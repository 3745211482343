'use client';

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import {
  DocumentIcon,
  TicketIcon,
  UserIcon,
  WalletIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useAuth } from '../hooks/use-auth';
import { Link } from 'react-router-dom';
import { useProtectedContext } from './protected-route';
import { useTickets } from '../api/queries';
import { useTranslation } from 'react-i18next';
import { handleinteractions } from '../helpers';
import { AddPhoneButton } from './add-phone-button';

export const Drawer = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { user } = useAuth();
  const { closeDrawer, openAddPhone } = useProtectedContext();
  const { paid, checkedIn } = useTickets(user?.id);

  const { i18n, t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/30 transition-opacity duration-150 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="text-white pointer-events-auto w-screen max-w-md transform transition duration-300 ease-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-bgPrimary py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-100">
                      <>
                        {t('general.hello')}
                        {', '}
                        {user?.name}
                      </>
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={closeDrawer}
                        className="relative rounded-full bg-bgSecondary text-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 p-2 "
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  {/* Tickets */}
                  <div className="border-2 rounded-lg p-2 flex-col flex gap-4 border-bgTertiary">
                    <span>{t('general.tickets')}</span>
                    <Link className="w-full" to="/dashboard/checked-in" onClick={closeDrawer}>
                      <button
                        disabled={checkedIn?.length === 0}
                        type="button"
                        className="w-full rounded-full bg-blue-500/10 border-blue-500 border-2 px-4 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex disabled:opacity-50 hover:bg-blue-500/10"
                      >
                        <TicketIcon className="h-5 w-5 text-blue-200" />
                        <span className="ml-2">{t('general.checked-in')}</span>
                        <span className="ml-auto bg-white rounded-full h-5 w-5 items-center justify-center text-black">
                          {checkedIn?.length}
                        </span>
                      </button>
                    </Link>

                    <Link className="w-full" to="/dashboard/paid" onClick={closeDrawer}>
                      <button
                        disabled={paid?.length === 0}
                        type="button"
                        className="w-full rounded-full bg-green-500/10 border-green-500 border-2 px-4 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex disabled:opacity-50 hover:bg-green-500/10"
                      >
                        <WalletIcon className="h-5 w-5 text-green-200" />
                        <span className="ml-2">{t('general.paid')}</span>

                        <span className="ml-auto bg-white rounded-full h-5 w-5 items-center justify-center text-black">
                          {paid?.length}
                        </span>
                      </button>
                    </Link>
                  </div>

                  <br />
                  {/* Other */}
                  <div className="flex flex-col gap-4">
                    <Link to="/receipts" className="w-full" onClick={closeDrawer}>
                      <button
                        type="button"
                        className="rounded-full bg-bgTertiary  p-4 text-sm font-medium text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex w-full"
                      >
                        <DocumentIcon className="h-5 w-5 text-blue-200" />
                        <span className="ml-2">{t('appbar.receipts')}</span>
                      </button>
                    </Link>

                    <Link to="/profile" className="w-full" onClick={closeDrawer}>
                      <button
                        type="button"
                        className="rounded-full bg-bgTertiary  p-4 text-sm font-medium text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex w-full"
                      >
                        <UserIcon className="h-5 w-5 text-blue-200" />
                        <span className="ml-2">{t('appbar.profile')}</span>
                      </button>
                    </Link>
                  </div>
                </div>
                {!user?.phone && (
                  <div className="p-4 flex flex-1 justify-center flex-col gap-2">
                    <AddPhoneButton
                      onClick={() => {
                        openAddPhone();
                        closeDrawer();
                      }}
                    />
                  </div>
                )}
                <div className="p-4">
                  <button
                    type="button"
                    className="w-full rounded-full bg-white px-3.5 py-2 text-sm font-medium text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    {...handleinteractions(() => {
                      if (i18n.language === 'sv') {
                        i18n.changeLanguage('en');
                      } else {
                        i18n.changeLanguage('sv');
                      }
                    })}
                  >
                    {i18n.language === 'sv' ? 'Svenska' : 'English'}
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
