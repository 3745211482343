/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTickets } from '../api/queries';

import { useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { LoadingView } from '.';
// import { CompleteUserProfile } from '../components/complete-user-profile';
import { DashboardLayout } from '../components/dashboard-layout';
import { useAuth } from '../contexts/auth-context';
import { CheckedInQrCode, PaidQRCode, QRCode } from '../generated';
import { CheckedInTicket } from './checked-in-ticket';
import { CheckedOutTicket } from './checked-out-ticket';
import { PaidTicket } from './paid-ticket';

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const DashboardView = () => {
  const { pathname } = useLocation();
  const isPaidView = pathname.includes('paid');
  const isCheckedInView = pathname.includes('checked-in');

  const { user } = useAuth();
  const { paid, checkedIn, personal } = useTickets(user?.user?.id);

  const slideTickets: (PaidQRCode | CheckedInQrCode | QRCode)[] =
    isPaidView && paid
      ? paid
      : isCheckedInView && checkedIn
        ? checkedIn
        : personal
          ? [personal]
          : [];

  useEffect(() => {
    const clampSlideIndex = () => {
      if (!slideTickets) return;

      const newSlideIndex = clamp(slideIndex, 0, slideTickets.length - 1);

      if (newSlideIndex !== slideIndex) {
        setSlideIndex(newSlideIndex);
      }

      return;
    };
    clampSlideIndex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideTickets]);

  const [slideIndex, setSlideIndex] = useState(0);

  if (!checkedIn || !paid) return <LoadingView />;

  return (
    <DashboardLayout>
      <div className="w-full items-center flex">
        <SwipeableViews
          disabled={slideTickets && slideTickets.length <= 1}
          onChangeIndex={(index) => setSlideIndex(index)}
          index={clamp(slideIndex, 0, slideTickets.length - 1)}
          enableMouseEvents
          style={{
            padding: '0 10%',
          }}
        >
          {slideTickets
            ?.map((qrCode, index) => {
              const { state } = qrCode;
              const key =
                qrCode.userId +
                qrCode.state +
                index +
                (qrCode as PaidQRCode).paymentTicketId +
                (qrCode as CheckedInQrCode).slotId;

              if (state === 'CHECKED_IN') {
                return (
                  <div key={key} style={{ height: '100%', padding: 10 }}>
                    <CheckedInTicket qrCode={qrCode} />
                  </div>
                );
              }

              if (state === 'PAID') {
                return (
                  <div key={key} style={{ height: '100%', padding: 10 }}>
                    <PaidTicket qrCode={qrCode} />
                  </div>
                );
              }

              if (state === 'CHECKED_OUT') {
                return (
                  <div key={key} style={{ height: '100%', padding: 10 }}>
                    <CheckedOutTicket qrCode={qrCode} />
                  </div>
                );
              }

              return null;
            })
            .filter(Boolean)}
        </SwipeableViews>
      </div>
    </DashboardLayout>
  );
};
