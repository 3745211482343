import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import {
  ArrowPathIcon,
  CheckIcon,
  ChevronUpDownIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../api';
import { useDashboardContext } from '../../context/dashboard';
import { FacilityForPayments } from '../../generated';
import { plausible, trackEvent } from '../../utils/track-events';

interface Props {
  selectedFacility?: FacilityForPayments;
  setSelectedFacility: React.Dispatch<React.SetStateAction<FacilityForPayments | undefined>>;
}

export const InitPaymentForm = ({ selectedFacility, setSelectedFacility }: Props) => {
  const [query, setQuery] = useState('');

  const { t } = useTranslation();

  const { data: facilitiesForPayments } = useQuery({
    queryKey: ['facilities', '-for-payments'],
    queryFn: async () => (await api.facilitiesControllerGetFacilitiesForPayments()).data,
    initialData: [],
  });

  const filteredFacilities =
    query === ''
      ? facilitiesForPayments
      : facilitiesForPayments.filter((facility) => {
          return (
            facility.name.toLowerCase().includes(query.toLowerCase()) ||
            facility.paymentCode.toLowerCase().includes(query.toLowerCase())
          );
        });

  const { paymentCode, setPaymentCode, handlePaymentCode, paymentCodeError, setPaymentCodeError } =
    useDashboardContext();

  const prefillPaymentCode = localStorage.getItem('prefillPaymentCode');
  const prefillFacility = facilitiesForPayments.find(
    (facility) => facility.paymentCode === prefillPaymentCode,
  );

  return (
    <div className="p-8">
      <h5 className="text-xl font-medium text-black mb-5">{t('dashboard.enter_payment_code')}</h5>
      <form
        className="flex flex-col gap-5"
        onSubmit={(e) => {
          e.preventDefault();
          if (paymentCodeError && setPaymentCodeError) setPaymentCodeError(false);
          plausible.trackPageview({ url: `${window.location.origin}/${paymentCode}` });
          trackEvent('manual-payment-code-entered');
          handlePaymentCode(paymentCode || selectedFacility?.paymentCode || '');
        }}
      >
        <Combobox
          as="div"
          value={selectedFacility}
          onChange={(facility) => {
            setQuery('');
            if (paymentCodeError && setPaymentCodeError) setPaymentCodeError(false);
            setSelectedFacility(facility as FacilityForPayments);
            setPaymentCode((facility as FacilityForPayments).paymentCode);
          }}
        >
          <div className="relative mt-2">
            <ComboboxInput
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
              onChange={(event) => {
                if (paymentCodeError && setPaymentCodeError) setPaymentCodeError(false);
                setQuery(event.target.value);
              }}
              onBlur={() => setQuery('')}
              placeholder="Search by name or code"
              displayValue={(person: { name: string; username: string } | null) =>
                person?.name || ''
              }
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            {filteredFacilities.length > 0 && (
              <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredFacilities?.map((facility) => (
                  <ComboboxOption
                    key={facility.id}
                    value={facility}
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
                  >
                    <div className="flex">
                      <span className="truncate group-data-[selected]:font-semibold">
                        {facility.name}
                      </span>
                      <span className="ml-2 truncate text-gray-500 group-data-[focus]:text-indigo-200">
                        {facility.paymentCode}
                      </span>
                    </div>

                    <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            )}
          </div>
        </Combobox>
        {paymentCodeError ? (
          <div>
            {selectedFacility?.paymentsActive ? (
              <p className="mt-2 text-sm text-red-600">Not a valid code.</p>
            ) : (
              <p className="mt-2 text-sm text-red-600">
                Payments are not active for this facility.
              </p>
            )}
          </div>
        ) : undefined}
        <button
          type="submit"
          className="w-full py-2 px-2 bg-black text-white text-lg font-medium rounded-md flex items-center justify-between hover:bg-gray-600 focus:outline focus:ring-2 focus:ring-gray-600"
        >
          {t('dashboard.continue')}
          <CreditCardIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </form>
      {/* <button
              onClick={() => {
                setCameraActive(true);
                (document.querySelector('#camera') as HTMLElement)?.showPopover();
              }}
              className="w-full py-2 px-2 bg-white text-black text-lg font-medium rounded-md flex items-center justify-between hover:bg-gray-600 focus:outline focus:ring-2 focus:ring-gray-600 border border-black"
            >
              Scan QR
              <CameraIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* <div id="camera" className="inset-0 fixed bg-black" popover>
              <Scanner onScan={console.log} />
            </div> */}
      {prefillFacility && prefillPaymentCode && (
        <>
          <br />
          <span className="text-sm text-gray-500">{t('dashboard.continue_with_latest')}: </span>
          <button
            className="w-full py-2 px-2 bg-white text-black text-sm font-medium rounded-md flex items-center justify-between hover:bg-gray-600 focus:outline focus:ring-2 focus:ring-gray-600 border border-black"
            onClick={() => {
              setSelectedFacility(prefillFacility);
              setPaymentCode(prefillPaymentCode);
              handlePaymentCode(prefillPaymentCode);
            }}
          >
            {prefillFacility.name}
            <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </>
      )}
    </div>
  );
};
