import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';
import { createContext, useContext, useState } from 'react';
import { Drawer } from './drawer';
import { DashboardContextProvider } from '../context/dashboard';
import { AddPhoneDialog } from './add-phone-dialog';

const ProtectedContext = createContext({
  drawerOpen: false,
  closeDrawer: () => {},
  openDrawer: () => {},
  toggleDrawer: () => {},
  addPhoneOpen: false,
  closeAddPhone: () => {},
  openAddPhone: () => {},

  personalTicketOpen: false,
  closePersonalTicket: () => {},
  openPersonalTicket: () => {},
});

export const ProtectedRoute = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [addPhoneOpen, setAddPhoneOpen] = useState(false);
  const closeDrawer = () => setDrawerOpen(false);
  const openDrawer = () => setDrawerOpen(true);
  const toggleDrawer = () => setDrawerOpen((prev) => !prev);

  const closeAddPhone = () => setAddPhoneOpen(false);
  const openAddPhone = () => setAddPhoneOpen(true);

  const [personalTicketOpen, setPersonalTicketOpen] = useState(false);
  const closePersonalTicket = () => setPersonalTicketOpen(false);
  const openPersonalTicket = () => setPersonalTicketOpen(true);

  const { isAuthenticated, loading } = useAuth();

  const { pathname } = useLocation();

  if (loading) return null;

  if (!isAuthenticated) {
    if (pathname.includes('/facility/')) {
      localStorage.setItem('redirect', pathname);
    }

    return <Navigate to={`/login${window.location.search}`} />;
  }

  return (
    <ProtectedContext.Provider
      value={{
        drawerOpen,
        closeDrawer,
        openDrawer,
        toggleDrawer,
        addPhoneOpen,
        closeAddPhone,
        openAddPhone,
        personalTicketOpen,
        closePersonalTicket,
        openPersonalTicket,
      }}
    >
      <DashboardContextProvider>
        <Outlet />
        <Drawer open={drawerOpen} onClose={closeDrawer} />
        <AddPhoneDialog open={addPhoneOpen} onClose={closeAddPhone} />
      </DashboardContextProvider>
    </ProtectedContext.Provider>
  );
};

export const useProtectedContext = () => {
  const context = useContext(ProtectedContext);

  if (!context) {
    throw new Error('useProtectedContext must be used within a ProtectedContextProvider');
  }

  return context;
};
