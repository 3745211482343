import { useState, useEffect, useCallback } from 'react';

import { SessionResponseError } from '../../generated';
import { ACCESS_TOKEN_KEY, api } from '../client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export const useBankIdGetSession = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<SessionResponseError>();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const sessionId = params.get('sessionId');

  const queryClient = useQueryClient();

  const fetcher = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.authControllerGetSession({
        id: sessionId ?? '',
      });

      if (
        data &&
        data.error &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (data.error.errorObject?.message?.status === 'pending' ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data.error?.grandidObject?.message?.status === 'pending')
      ) {
        setTimeout(fetcher, 1000);
        return;
      }

      if (data && data.error) {
        setIsLoading(false);
        setErrorData(data.error);
      }

      if (data && data.ok) {
        data.ok.accessToken && localStorage.setItem(ACCESS_TOKEN_KEY, data.ok.accessToken);
        queryClient.invalidateQueries(['currentUser']);
        navigate('/dashboard');
      }
    } catch (error) {
      setIsLoading(false);
      setErrorData({
        grandidObject: {
          message: {
            status: 'error',
          },
        },
      });
    }
  }, [sessionId, queryClient, navigate]);

  useEffect(() => {
    if (!sessionId) return;
    fetcher();
  }, [fetcher, sessionId]);

  return {
    errorData,
    isLoading,
  };
};
