/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../api';
import { useAuth } from '../hooks/use-auth';
import { PhoneNumberForm } from './forms/phone-number-form';
import { VerificationCodeForm } from './forms/verification-code-form';
import { Spinner } from './spinner';
import { Dialog } from './ui/dialog';

export const AddPhoneDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();

  const { user } = useAuth();
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const handlePhoneSubmit = async (phone: string) => {
    setLoading(true);
    setPhone(phone);
    try {
      await api.usersControllerInitAddPhone({
        id: user?.id as string,
        phone,
      });
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      setMessage(error?.response?.data?.message || 'An error occurred');
      setLoading(false);
      setPhone('');
    }
  };

  const handleVerification = async (phone: string, otp: string) => {
    setLoading(true);
    try {
      await api.usersControllerConfirmAddPhone({
        id: user?.id as string,
        phone,
        otp,
      });
      setPhone('');
      setLoading(false);
      queryClient.invalidateQueries({ queryKey: ['auth'] });
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      onClose();
    } catch (error: any) {
      console.error(error);
      setMessage(error?.response?.data?.message || 'An error occurred');
      setLoading(false);
      setPhone('');
    }
  };

  const handleRetry = () => {
    setPhone('');
  };

  const render = () => {
    if (loading)
      return (
        <div className="flex justify-center">
          <Spinner />
        </div>
      );
    if (!phone)
      return (
        <div>
          <h2 className="text-blue-300 text-lg mb-2">{t('general.account-safety')}</h2>
          {message && <p className="text-red-500">{message}</p>}
          <PhoneNumberForm onSubmit={handlePhoneSubmit} />
        </div>
      );
    return (
      <VerificationCodeForm onSubmit={handleVerification} onRetry={handleRetry} phone={phone} />
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {render()}
    </Dialog>
  );
};
