import { useQuery } from '@tanstack/react-query';
import { api } from '../api';
import { useSearchParams } from 'react-router-dom';

export const useFeatureFlag = (feature: string) => {
  const [searchParams] = useSearchParams();
  const paymentCode = searchParams.get('paymentCode');

  const query = useQuery({
    queryKey: ['feature-flag', feature, paymentCode],
    queryFn: async () => {
      const { data } = await api.featureFlagControllerIsEnabledForPaymentCode({
        feature,
        paymentCode: paymentCode || '',
      });
      return data;
    },
    enabled: !!paymentCode,
  });

  const enabled = !!query.data;

  return { ...query, enabled };
};

export const useHideBankId = () => useFeatureFlag('hide-bankid');
