/* tslint:disable */
/* eslint-disable */
/**
 * EasyJacket API
 * The EasyJacket API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddAdditionalUserToSlotDto
 */
export interface AddAdditionalUserToSlotDto {
    /**
     * User id of the user who is being added to the slot
     * @type {string}
     * @memberof AddAdditionalUserToSlotDto
     */
    'userId': string;
    /**
     * If the user wants to skip the payment
     * @type {boolean}
     * @memberof AddAdditionalUserToSlotDto
     */
    'skipPayment'?: boolean;
    /**
     * Type of check in
     * @type {string}
     * @memberof AddAdditionalUserToSlotDto
     */
    'type': AddAdditionalUserToSlotDtoTypeEnum;
}

export const AddAdditionalUserToSlotDtoTypeEnum = {
    In: 'CHECK_IN',
    InVip: 'CHECK_IN_VIP',
    InExpress: 'CHECK_IN_EXPRESS'
} as const;

export type AddAdditionalUserToSlotDtoTypeEnum = typeof AddAdditionalUserToSlotDtoTypeEnum[keyof typeof AddAdditionalUserToSlotDtoTypeEnum];

/**
 * 
 * @export
 * @interface AdditionalSlotUsers
 */
export interface AdditionalSlotUsers {
    /**
     * 
     * @type {string}
     * @memberof AdditionalSlotUsers
     */
    'slotId': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalSlotUsers
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface AdminUser
 */
export interface AdminUser {
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'currentZoneId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'otpExpiry'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'password': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'role': AdminUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'facilityId': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUser
     */
    'otp': string;
    /**
     * 
     * @type {Facility}
     * @memberof AdminUser
     */
    'facility'?: Facility;
}

export const AdminUserRoleEnum = {
    SuperAdmin: 'SUPER_ADMIN',
    FacilityAdmin: 'FACILITY_ADMIN',
    WardrobeAdmin: 'WARDROBE_ADMIN'
} as const;

export type AdminUserRoleEnum = typeof AdminUserRoleEnum[keyof typeof AdminUserRoleEnum];

/**
 * 
 * @export
 * @interface AdminUserWithoutFacility
 */
export interface AdminUserWithoutFacility {
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'currentZoneId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'otpExpiry'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'password': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'role': AdminUserWithoutFacilityRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'facilityId': string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserWithoutFacility
     */
    'otp': string;
}

export const AdminUserWithoutFacilityRoleEnum = {
    SuperAdmin: 'SUPER_ADMIN',
    FacilityAdmin: 'FACILITY_ADMIN',
    WardrobeAdmin: 'WARDROBE_ADMIN'
} as const;

export type AdminUserWithoutFacilityRoleEnum = typeof AdminUserWithoutFacilityRoleEnum[keyof typeof AdminUserWithoutFacilityRoleEnum];

/**
 * 
 * @export
 * @interface ArchiveDto
 */
export interface ArchiveDto {
    /**
     * 
     * @type {string}
     * @memberof ArchiveDto
     */
    'slotId': string;
    /**
     * 
     * @type {string}
     * @memberof ArchiveDto
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface AuthUser
 */
export interface AuthUser {
    /**
     * 
     * @type {User}
     * @memberof AuthUser
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    'accessToken': string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUser
     */
    'profileComplete': boolean;
}
/**
 * 
 * @export
 * @interface BulkSmsWithShortUrls
 */
export interface BulkSmsWithShortUrls {
    /**
     * 
     * @type {string}
     * @memberof BulkSmsWithShortUrls
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof BulkSmsWithShortUrls
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BulkSmsWithShortUrls
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BulkSmsWithShortUrls
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof BulkSmsWithShortUrls
     */
    'sentMessages': number;
    /**
     * 
     * @type {number}
     * @memberof BulkSmsWithShortUrls
     */
    'failedMessages': number;
    /**
     * 
     * @type {number}
     * @memberof BulkSmsWithShortUrls
     */
    'totalCost': number;
    /**
     * 
     * @type {string}
     * @memberof BulkSmsWithShortUrls
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof BulkSmsWithShortUrls
     */
    'adminUserId': string;
    /**
     * 
     * @type {AdminUserWithoutFacility}
     * @memberof BulkSmsWithShortUrls
     */
    'adminUser': AdminUserWithoutFacility;
    /**
     * 
     * @type {Facility}
     * @memberof BulkSmsWithShortUrls
     */
    'facility': Facility;
    /**
     * 
     * @type {Array<ShortUrl>}
     * @memberof BulkSmsWithShortUrls
     */
    'shortUrls': Array<ShortUrl>;
}
/**
 * 
 * @export
 * @interface ChartData
 */
export interface ChartData {
    /**
     * 
     * @type {string}
     * @memberof ChartData
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ChartData
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof ChartData
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface CheckInDto
 */
export interface CheckInDto {
    /**
     * User id of the user who is checking in
     * @type {string}
     * @memberof CheckInDto
     */
    'userId': string;
    /**
     * If the reserved jacket slot should be checked in
     * @type {boolean}
     * @memberof CheckInDto
     */
    'hasJacket': boolean;
    /**
     * If the reserved bag slot should be checked in
     * @type {boolean}
     * @memberof CheckInDto
     */
    'hasSeparateBag': boolean;
    /**
     * If user includes bag on same slot as jacket
     * @type {boolean}
     * @memberof CheckInDto
     */
    'hasBag': boolean;
    /**
     * If the user wants to skip the payment
     * @type {boolean}
     * @memberof CheckInDto
     */
    'skipPayment'?: boolean;
    /**
     * Ticket id of the payment and user
     * @type {string}
     * @memberof CheckInDto
     */
    'ticketId'?: string;
    /**
     * Type of check in
     * @type {string}
     * @memberof CheckInDto
     */
    'type': CheckInDtoTypeEnum;
}

export const CheckInDtoTypeEnum = {
    In: 'CHECK_IN',
    InVip: 'CHECK_IN_VIP',
    InExpress: 'CHECK_IN_EXPRESS'
} as const;

export type CheckInDtoTypeEnum = typeof CheckInDtoTypeEnum[keyof typeof CheckInDtoTypeEnum];

/**
 * 
 * @export
 * @interface CheckOutDto
 */
export interface CheckOutDto {
    /**
     * 
     * @type {string}
     * @memberof CheckOutDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CheckOutDto
     */
    'checkoutType'?: CheckOutDtoCheckoutTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckOutDto
     */
    'isPrintscreen'?: string;
}

export const CheckOutDtoCheckoutTypeEnum = {
    Out: 'CHECK_OUT',
    OutManual: 'CHECK_OUT_MANUAL',
    OutPrintscreen: 'CHECK_OUT_PRINTSCREEN'
} as const;

export type CheckOutDtoCheckoutTypeEnum = typeof CheckOutDtoCheckoutTypeEnum[keyof typeof CheckOutDtoCheckoutTypeEnum];

/**
 * 
 * @export
 * @interface CheckedInQrCode
 */
export interface CheckedInQrCode {
    /**
     * 
     * @type {string}
     * @memberof CheckedInQrCode
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CheckedInQrCode
     */
    'zoneName': string;
    /**
     * 
     * @type {number}
     * @memberof CheckedInQrCode
     */
    'slotNumber': number;
    /**
     * 
     * @type {string}
     * @memberof CheckedInQrCode
     */
    'slotId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckedInQrCode
     */
    'customZoneColors'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckedInQrCode
     */
    'zoneColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckedInQrCode
     */
    'facilityName': string;
    /**
     * 
     * @type {string}
     * @memberof CheckedInQrCode
     */
    'state': CheckedInQrCodeStateEnum;
    /**
     * 
     * @type {number}
     * @memberof CheckedInQrCode
     */
    'timeStamp': number;
}

export const CheckedInQrCodeStateEnum = {
    CheckedIn: 'CHECKED_IN'
} as const;

export type CheckedInQrCodeStateEnum = typeof CheckedInQrCodeStateEnum[keyof typeof CheckedInQrCodeStateEnum];

/**
 * 
 * @export
 * @interface CheckoutSession
 */
export interface CheckoutSession {
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'object': string;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'after_expiration': object;
    /**
     * 
     * @type {boolean}
     * @memberof CheckoutSession
     */
    'allow_promotion_codes': boolean;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSession
     */
    'amount_subtotal': number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSession
     */
    'amount_total': number;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'automatic_tax': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'billing_address_collection': object;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'cancel_url': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'client_reference_id': string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'client_secret': string;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'consent': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'consent_collection': object;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSession
     */
    'created': number;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'currency': string;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'currency_conversion': object;
    /**
     * 
     * @type {Array<object>}
     * @memberof CheckoutSession
     */
    'custom_fields': Array<object>;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'custom_text': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'customer': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'customer_creation': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'customer_details': object;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'customer_email': string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSession
     */
    'expires_at': number;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'invoice': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'invoice_creation': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'line_items'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof CheckoutSession
     */
    'livemode': boolean;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'locale': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'metadata': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'mode': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'payment_intent': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'payment_link': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'payment_method_collection': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'payment_method_configuration_details': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'payment_method_options': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckoutSession
     */
    'payment_method_types': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'payment_status': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'phone_number_collection'?: object;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'recovered_from': string;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'redirect_on_completion'?: object;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'return_url'?: string;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'setup_intent': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'shipping_address_collection': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'shipping_cost': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'shipping_details': object;
    /**
     * 
     * @type {Array<object>}
     * @memberof CheckoutSession
     */
    'shipping_options': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'status': CheckoutSessionStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'submit_type': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'subscription': object;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'success_url': string;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'tax_id_collection'?: object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'total_details': object;
    /**
     * 
     * @type {object}
     * @memberof CheckoutSession
     */
    'ui_mode': object;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSession
     */
    'url': string;
}

export const CheckoutSessionStatusEnum = {
    Complete: 'complete',
    Expired: 'expired',
    Open: 'open'
} as const;

export type CheckoutSessionStatusEnum = typeof CheckoutSessionStatusEnum[keyof typeof CheckoutSessionStatusEnum];

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'bankgiro': string;
}
/**
 * 
 * @export
 * @interface CreateAdminUserDto
 */
export interface CreateAdminUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminUserDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CreateCompanyDto
 */
export interface CreateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'bankgiro'?: string;
}
/**
 * 
 * @export
 * @interface CreateFacilityDto
 */
export interface CreateFacilityDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFacilityDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFacilityDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFacilityDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFacilityDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CreateRefundRequestDto
 */
export interface CreateRefundRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRefundRequestDto
     */
    'paymentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRefundRequestDto
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRefundRequestDto
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface CreateReportDto
 */
export interface CreateReportDto {
    /**
     * 
     * @type {string}
     * @memberof CreateReportDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReportDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateReportDto
     */
    'facilityId': string;
}
/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'ssn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'birthdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CreateWardrobeAdminDto
 */
export interface CreateWardrobeAdminDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWardrobeAdminDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWardrobeAdminDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWardrobeAdminDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWardrobeAdminDto
     */
    'facilityId'?: string;
}
/**
 * 
 * @export
 * @interface CreateZoneDto
 */
export interface CreateZoneDto {
    /**
     * 
     * @type {number}
     * @memberof CreateZoneDto
     */
    'slotStart': number;
    /**
     * 
     * @type {number}
     * @memberof CreateZoneDto
     */
    'slotEnd': number;
    /**
     * 
     * @type {string}
     * @memberof CreateZoneDto
     */
    'name': CreateZoneDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateZoneDto
     */
    'type'?: CreateZoneDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateZoneDto
     */
    'color'?: string;
}

export const CreateZoneDtoNameEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G',
    H: 'H',
    I: 'I',
    J: 'J',
    K: 'K',
    L: 'L',
    M: 'M',
    N: 'N',
    O: 'O',
    P: 'P',
    Q: 'Q',
    R: 'R',
    S: 'S',
    T: 'T',
    U: 'U',
    V: 'V',
    X: 'X',
    Y: 'Y',
    Z: 'Z',
    W: 'W',
    Archive: 'ARCHIVE',
    Bag: 'BAG'
} as const;

export type CreateZoneDtoNameEnum = typeof CreateZoneDtoNameEnum[keyof typeof CreateZoneDtoNameEnum];
export const CreateZoneDtoTypeEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G',
    H: 'H',
    I: 'I',
    J: 'J',
    K: 'K',
    L: 'L',
    M: 'M',
    N: 'N',
    O: 'O',
    P: 'P',
    Q: 'Q',
    R: 'R',
    S: 'S',
    T: 'T',
    U: 'U',
    V: 'V',
    X: 'X',
    Y: 'Y',
    Z: 'Z',
    W: 'W',
    Archive: 'ARCHIVE',
    Bag: 'BAG'
} as const;

export type CreateZoneDtoTypeEnum = typeof CreateZoneDtoTypeEnum[keyof typeof CreateZoneDtoTypeEnum];

/**
 * 
 * @export
 * @interface Dashboard
 */
export interface Dashboard {
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof Dashboard
     */
    'genderGroups': Array<ChartData>;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    'totalCheckins': number;
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof Dashboard
     */
    'checkinsPerFiveMinutes': Array<ChartData>;
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof Dashboard
     */
    'checkoutsPerFiveMinutes': Array<ChartData>;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    'totalTickets': number;
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof Dashboard
     */
    'ticketsPerFiveMinutes': Array<ChartData>;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    'totalConsumedTickets': number;
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof Dashboard
     */
    'consumedPerFiveMinutes': Array<ChartData>;
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof Dashboard
     */
    'ageGroups': Array<ChartData>;
    /**
     * 
     * @type {string}
     * @memberof Dashboard
     */
    'averageTimeSpent': string;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    'totalIncome': number;
}
/**
 * 
 * @export
 * @interface EntranceSummary
 */
export interface EntranceSummary {
    /**
     * 
     * @type {number}
     * @memberof EntranceSummary
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof EntranceSummary
     */
    'consumed': number;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'type': EventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'slotId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'adminId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'previousEventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'nextEventId'?: string;
}

export const EventTypeEnum = {
    Archived: 'ARCHIVED',
    CheckIn: 'CHECK_IN',
    CheckInExpress: 'CHECK_IN_EXPRESS',
    CheckInVip: 'CHECK_IN_VIP',
    CheckOut: 'CHECK_OUT',
    CheckOutManual: 'CHECK_OUT_MANUAL',
    CheckOutPrintscreen: 'CHECK_OUT_PRINTSCREEN',
    Disabled: 'DISABLED',
    Enabled: 'ENABLED',
    Move: 'MOVE'
} as const;

export type EventTypeEnum = typeof EventTypeEnum[keyof typeof EventTypeEnum];

/**
 * 
 * @export
 * @interface EventWithZoneAndSlot
 */
export interface EventWithZoneAndSlot {
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlot
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlot
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlot
     */
    'type': EventWithZoneAndSlotTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlot
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlot
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlot
     */
    'slotId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlot
     */
    'adminId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlot
     */
    'previousEventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlot
     */
    'nextEventId'?: string;
    /**
     * 
     * @type {SlotWithZone}
     * @memberof EventWithZoneAndSlot
     */
    'slot'?: SlotWithZone;
}

export const EventWithZoneAndSlotTypeEnum = {
    Archived: 'ARCHIVED',
    CheckIn: 'CHECK_IN',
    CheckInExpress: 'CHECK_IN_EXPRESS',
    CheckInVip: 'CHECK_IN_VIP',
    CheckOut: 'CHECK_OUT',
    CheckOutManual: 'CHECK_OUT_MANUAL',
    CheckOutPrintscreen: 'CHECK_OUT_PRINTSCREEN',
    Disabled: 'DISABLED',
    Enabled: 'ENABLED',
    Move: 'MOVE'
} as const;

export type EventWithZoneAndSlotTypeEnum = typeof EventWithZoneAndSlotTypeEnum[keyof typeof EventWithZoneAndSlotTypeEnum];

/**
 * 
 * @export
 * @interface EventWithZoneAndSlotAndAdminUserAndUser
 */
export interface EventWithZoneAndSlotAndAdminUserAndUser {
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'type': EventWithZoneAndSlotAndAdminUserAndUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'slotId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'adminId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'previousEventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'nextEventId'?: string;
    /**
     * 
     * @type {SlotWithZone}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'slot'?: SlotWithZone;
    /**
     * 
     * @type {AdminUserWithoutFacility}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'admin'?: AdminUserWithoutFacility;
    /**
     * 
     * @type {User}
     * @memberof EventWithZoneAndSlotAndAdminUserAndUser
     */
    'user'?: User;
}

export const EventWithZoneAndSlotAndAdminUserAndUserTypeEnum = {
    Archived: 'ARCHIVED',
    CheckIn: 'CHECK_IN',
    CheckInExpress: 'CHECK_IN_EXPRESS',
    CheckInVip: 'CHECK_IN_VIP',
    CheckOut: 'CHECK_OUT',
    CheckOutManual: 'CHECK_OUT_MANUAL',
    CheckOutPrintscreen: 'CHECK_OUT_PRINTSCREEN',
    Disabled: 'DISABLED',
    Enabled: 'ENABLED',
    Move: 'MOVE'
} as const;

export type EventWithZoneAndSlotAndAdminUserAndUserTypeEnum = typeof EventWithZoneAndSlotAndAdminUserAndUserTypeEnum[keyof typeof EventWithZoneAndSlotAndAdminUserAndUserTypeEnum];

/**
 * 
 * @export
 * @interface EventWithZoneAndSlotAndPreviousAndNext
 */
export interface EventWithZoneAndSlotAndPreviousAndNext {
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'type': EventWithZoneAndSlotAndPreviousAndNextTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'slotId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'adminId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'previousEventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'nextEventId'?: string;
    /**
     * 
     * @type {SlotWithZone}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'slot'?: SlotWithZone;
    /**
     * 
     * @type {Event}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'previousEvent'?: Event;
    /**
     * 
     * @type {Event}
     * @memberof EventWithZoneAndSlotAndPreviousAndNext
     */
    'nextEvent'?: Event;
}

export const EventWithZoneAndSlotAndPreviousAndNextTypeEnum = {
    Archived: 'ARCHIVED',
    CheckIn: 'CHECK_IN',
    CheckInExpress: 'CHECK_IN_EXPRESS',
    CheckInVip: 'CHECK_IN_VIP',
    CheckOut: 'CHECK_OUT',
    CheckOutManual: 'CHECK_OUT_MANUAL',
    CheckOutPrintscreen: 'CHECK_OUT_PRINTSCREEN',
    Disabled: 'DISABLED',
    Enabled: 'ENABLED',
    Move: 'MOVE'
} as const;

export type EventWithZoneAndSlotAndPreviousAndNextTypeEnum = typeof EventWithZoneAndSlotAndPreviousAndNextTypeEnum[keyof typeof EventWithZoneAndSlotAndPreviousAndNextTypeEnum];

/**
 * 
 * @export
 * @interface Facility
 */
export interface Facility {
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'smsSenderAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'paymentCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof Facility
     */
    'wardrobeActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Facility
     */
    'entranceActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'facilityGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'companyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Facility
     */
    'paymentDayPass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Facility
     */
    'isTestFacility': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Facility
     */
    'paymentsActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof Facility
     */
    'paymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof Facility
     */
    'customZoneColors': boolean;
}
/**
 * 
 * @export
 * @interface FacilityForPayments
 */
export interface FacilityForPayments {
    /**
     * 
     * @type {string}
     * @memberof FacilityForPayments
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityForPayments
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityForPayments
     */
    'paymentCode': string;
    /**
     * 
     * @type {number}
     * @memberof FacilityForPayments
     */
    'paymentAmount': number;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityForPayments
     */
    'paymentDayPass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityForPayments
     */
    'paymentsActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityForPayments
     */
    'wardrobeActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityForPayments
     */
    'entranceActive': boolean;
}
/**
 * 
 * @export
 * @interface FacilityHistory
 */
export interface FacilityHistory {
    /**
     * 
     * @type {string}
     * @memberof FacilityHistory
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityHistory
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityHistory
     */
    'adminUserId': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityHistory
     */
    'createdAt': string;
    /**
     * 
     * @type {object}
     * @memberof FacilityHistory
     */
    'previousValues': object;
    /**
     * 
     * @type {object}
     * @memberof FacilityHistory
     */
    'newValues': object;
}
/**
 * 
 * @export
 * @interface FacilityWithAndZonesAndSlots
 */
export interface FacilityWithAndZonesAndSlots {
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'smsSenderAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'paymentCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'wardrobeActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'entranceActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'facilityGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'companyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'paymentDayPass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'isTestFacility': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'paymentsActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'paymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'customZoneColors': boolean;
    /**
     * 
     * @type {Array<ZoneWithSlots>}
     * @memberof FacilityWithAndZonesAndSlots
     */
    'zones': Array<ZoneWithSlots>;
}
/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface GatewayAPICallbackBody
 */
export interface GatewayAPICallbackBody {
    /**
     * 
     * @type {number}
     * @memberof GatewayAPICallbackBody
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof GatewayAPICallbackBody
     */
    'msisdn': number;
    /**
     * 
     * @type {number}
     * @memberof GatewayAPICallbackBody
     */
    'time': number;
    /**
     * 
     * @type {object}
     * @memberof GatewayAPICallbackBody
     */
    'status': object;
    /**
     * 
     * @type {string}
     * @memberof GatewayAPICallbackBody
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof GatewayAPICallbackBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GatewayAPICallbackBody
     */
    'userref': string;
    /**
     * 
     * @type {string}
     * @memberof GatewayAPICallbackBody
     */
    'country_code': string;
    /**
     * 
     * @type {number}
     * @memberof GatewayAPICallbackBody
     */
    'country_prefix': number;
}
/**
 * 
 * @export
 * @interface InitAdminPhoneDto
 */
export interface InitAdminPhoneDto {
    /**
     * 
     * @type {string}
     * @memberof InitAdminPhoneDto
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface InitPaymentDto
 */
export interface InitPaymentDto {
    /**
     * 
     * @type {number}
     * @memberof InitPaymentDto
     */
    'numberOfTickets': number;
}
/**
 * 
 * @export
 * @interface InitResponse
 */
export interface InitResponse {
    /**
     * 
     * @type {string}
     * @memberof InitResponse
     */
    'sessionId': string;
    /**
     * 
     * @type {string}
     * @memberof InitResponse
     */
    'autoStartToken': string;
    /**
     * 
     * @type {string}
     * @memberof InitResponse
     */
    'QRCode': string;
}
/**
 * 
 * @export
 * @interface InitUserPhoneSignInDto
 */
export interface InitUserPhoneSignInDto {
    /**
     * 
     * @type {string}
     * @memberof InitUserPhoneSignInDto
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface MoveDto
 */
export interface MoveDto {
    /**
     * 
     * @type {string}
     * @memberof MoveDto
     */
    'previousSlotId': string;
    /**
     * 
     * @type {string}
     * @memberof MoveDto
     */
    'newSlotId': string;
}
/**
 * 
 * @export
 * @interface Overview
 */
export interface Overview {
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof Overview
     */
    'checkinsByFacility': Array<ChartData>;
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof Overview
     */
    'paymentsByFacility': Array<ChartData>;
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof Overview
     */
    'amountsByFacility': Array<ChartData>;
    /**
     * 
     * @type {Array<ChartData>}
     * @memberof Overview
     */
    'ticketsByFacility': Array<ChartData>;
}
/**
 * 
 * @export
 * @interface OverviewData
 */
export interface OverviewData {
    /**
     * 
     * @type {string}
     * @memberof OverviewData
     */
    'facilityId': string;
    /**
     * 
     * @type {number}
     * @memberof OverviewData
     */
    '_count': number;
    /**
     * 
     * @type {Sum}
     * @memberof OverviewData
     */
    '_sum'?: Sum;
}
/**
 * 
 * @export
 * @interface PaidQRCode
 */
export interface PaidQRCode {
    /**
     * 
     * @type {string}
     * @memberof PaidQRCode
     */
    'facilityName': string;
    /**
     * 
     * @type {string}
     * @memberof PaidQRCode
     */
    'paymentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaidQRCode
     */
    'paymentFacilityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaidQRCode
     */
    'paymentFacilityName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaidQRCode
     */
    'paymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaidQRCode
     */
    'paymentTicketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaidQRCode
     */
    'entranceConsumedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaidQRCode
     */
    'entranceActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaidQRCode
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof PaidQRCode
     */
    'state': PaidQRCodeStateEnum;
    /**
     * 
     * @type {number}
     * @memberof PaidQRCode
     */
    'timeStamp': number;
}

export const PaidQRCodeStateEnum = {
    Paid: 'PAID'
} as const;

export type PaidQRCodeStateEnum = typeof PaidQRCodeStateEnum[keyof typeof PaidQRCodeStateEnum];

/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'entranceConsumedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'numberOfTickets': number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'swishPaymentId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Payment
     */
    'consumed': boolean;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'userId': string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'status': PaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'paymentMethod': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'stripePaymentId': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'facilityId': string;
}

export const PaymentStatusEnum = {
    Pending: 'PENDING',
    Completed: 'COMPLETED',
    Failed: 'FAILED',
    Refunded: 'REFUNDED'
} as const;

export type PaymentStatusEnum = typeof PaymentStatusEnum[keyof typeof PaymentStatusEnum];

/**
 * 
 * @export
 * @interface PaymentIntent
 */
export interface PaymentIntent {
    /**
     * 
     * @type {string}
     * @memberof PaymentIntent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntent
     */
    'object': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentIntent
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentIntent
     */
    'amount_capturable': number;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'amount_details'?: object;
    /**
     * 
     * @type {number}
     * @memberof PaymentIntent
     */
    'amount_received': number;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'application': object;
    /**
     * 
     * @type {number}
     * @memberof PaymentIntent
     */
    'application_fee_amount': number;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'automatic_payment_methods': object;
    /**
     * 
     * @type {number}
     * @memberof PaymentIntent
     */
    'canceled_at': number;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'cancellation_reason': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'capture_method': object;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntent
     */
    'client_secret': string;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'confirmation_method': object;
    /**
     * 
     * @type {number}
     * @memberof PaymentIntent
     */
    'created': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntent
     */
    'currency': string;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'customer': object;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntent
     */
    'description': string;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'invoice': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'last_payment_error': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'latest_charge': object;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentIntent
     */
    'livemode': boolean;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'metadata': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'next_action': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'on_behalf_of': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'payment_method': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'payment_method_configuration_details': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'payment_method_options': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentIntent
     */
    'payment_method_types': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'processing': object;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntent
     */
    'receipt_email': string;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'review': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'setup_future_usage': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'shipping': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'source': object;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntent
     */
    'statement_descriptor': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntent
     */
    'statement_descriptor_suffix': string;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'status': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentIntent
     */
    'transfer_data': object;
    /**
     * 
     * @type {string}
     * @memberof PaymentIntent
     */
    'transfer_group': string;
}
/**
 * 
 * @export
 * @interface PaymentWithFacility
 */
export interface PaymentWithFacility {
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'entranceConsumedAt': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentWithFacility
     */
    'numberOfTickets': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'swishPaymentId': string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentWithFacility
     */
    'consumed': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'userId': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentWithFacility
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'status': PaymentWithFacilityStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'paymentMethod': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'stripePaymentId': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacility
     */
    'facilityId': string;
    /**
     * 
     * @type {Facility}
     * @memberof PaymentWithFacility
     */
    'facility': Facility;
}

export const PaymentWithFacilityStatusEnum = {
    Pending: 'PENDING',
    Completed: 'COMPLETED',
    Failed: 'FAILED',
    Refunded: 'REFUNDED'
} as const;

export type PaymentWithFacilityStatusEnum = typeof PaymentWithFacilityStatusEnum[keyof typeof PaymentWithFacilityStatusEnum];

/**
 * 
 * @export
 * @interface PaymentWithFacilityAndReceiptAndRefundRequests
 */
export interface PaymentWithFacilityAndReceiptAndRefundRequests {
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'entranceConsumedAt': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'numberOfTickets': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'swishPaymentId': string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'consumed': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'userId': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'status': PaymentWithFacilityAndReceiptAndRefundRequestsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'paymentMethod': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'stripePaymentId': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'facilityId': string;
    /**
     * 
     * @type {Facility}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'facility': Facility;
    /**
     * 
     * @type {string}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'receiptUrl'?: string;
    /**
     * 
     * @type {Array<RefundRequest>}
     * @memberof PaymentWithFacilityAndReceiptAndRefundRequests
     */
    'refundRequests': Array<RefundRequest>;
}

export const PaymentWithFacilityAndReceiptAndRefundRequestsStatusEnum = {
    Pending: 'PENDING',
    Completed: 'COMPLETED',
    Failed: 'FAILED',
    Refunded: 'REFUNDED'
} as const;

export type PaymentWithFacilityAndReceiptAndRefundRequestsStatusEnum = typeof PaymentWithFacilityAndReceiptAndRefundRequestsStatusEnum[keyof typeof PaymentWithFacilityAndReceiptAndRefundRequestsStatusEnum];

/**
 * 
 * @export
 * @interface QRCode
 */
export interface QRCode {
    /**
     * 
     * @type {string}
     * @memberof QRCode
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof QRCode
     */
    'state': QRCodeStateEnum;
    /**
     * 
     * @type {number}
     * @memberof QRCode
     */
    'timeStamp': number;
}

export const QRCodeStateEnum = {
    CheckedOut: 'CHECKED_OUT'
} as const;

export type QRCodeStateEnum = typeof QRCodeStateEnum[keyof typeof QRCodeStateEnum];

/**
 * 
 * @export
 * @interface QrCodes
 */
export interface QrCodes {
    /**
     * 
     * @type {QRCode}
     * @memberof QrCodes
     */
    'personal': QRCode;
    /**
     * 
     * @type {Array<PaidQRCode>}
     * @memberof QrCodes
     */
    'paid': Array<PaidQRCode>;
    /**
     * 
     * @type {Array<CheckedInQrCode>}
     * @memberof QrCodes
     */
    'checkedIn': Array<CheckedInQrCode>;
}
/**
 * 
 * @export
 * @interface RedirectResponse
 */
export interface RedirectResponse {
    /**
     * 
     * @type {string}
     * @memberof RedirectResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface RefundRequest
 */
export interface RefundRequest {
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'swishRefundId': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'refundedAt': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'paymentId': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'status': RefundRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'adminUserId': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'handledAt': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequest
     */
    'updatedAt': string;
}

export const RefundRequestStatusEnum = {
    Requested: 'REQUESTED',
    Pending: 'PENDING',
    Failed: 'FAILED',
    Approved: 'APPROVED',
    Denied: 'DENIED'
} as const;

export type RefundRequestStatusEnum = typeof RefundRequestStatusEnum[keyof typeof RefundRequestStatusEnum];

/**
 * 
 * @export
 * @interface RefundRequestWithPayment
 */
export interface RefundRequestWithPayment {
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'swishRefundId': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'refundedAt': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'paymentId': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'status': RefundRequestWithPaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'adminUserId': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'handledAt': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RefundRequestWithPayment
     */
    'updatedAt': string;
    /**
     * 
     * @type {Payment}
     * @memberof RefundRequestWithPayment
     */
    'payment': Payment;
}

export const RefundRequestWithPaymentStatusEnum = {
    Requested: 'REQUESTED',
    Pending: 'PENDING',
    Failed: 'FAILED',
    Approved: 'APPROVED',
    Denied: 'DENIED'
} as const;

export type RefundRequestWithPaymentStatusEnum = typeof RefundRequestWithPaymentStatusEnum[keyof typeof RefundRequestWithPaymentStatusEnum];

/**
 * 
 * @export
 * @interface ReportData
 */
export interface ReportData {
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalSales': number;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalSalesSwish': number;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalSalesStripe': number;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'easyjacketFee': number;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalNetSales': number;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalPayments': number;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalTickets': number;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalTicketsSwish': number;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalTicketsStripe': number;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalExpress': number;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof ReportData
     */
    'payments': Array<Payment>;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalSms': number;
    /**
     * 
     * @type {number}
     * @memberof ReportData
     */
    'totalSmsCost': number;
}
/**
 * 
 * @export
 * @interface ReserveSlotDto
 */
export interface ReserveSlotDto {
    /**
     * Zone id of the zone to reserve a slot in. Will be either of ZoneType.JACKET or ZoneType.BAG
     * @type {string}
     * @memberof ReserveSlotDto
     */
    'zoneId': string;
    /**
     * User id of the user who is reserving the slot
     * @type {string}
     * @memberof ReserveSlotDto
     */
    'userId': string;
    /**
     * If the user wants to skip the payment
     * @type {boolean}
     * @memberof ReserveSlotDto
     */
    'skipPayment'?: boolean;
}
/**
 * 
 * @export
 * @interface SearchByBirthdateDto
 */
export interface SearchByBirthdateDto {
    /**
     * 
     * @type {string}
     * @memberof SearchByBirthdateDto
     */
    'birthdate'?: string;
}
/**
 * 
 * @export
 * @interface SendSmsDto
 */
export interface SendSmsDto {
    /**
     * 
     * @type {string}
     * @memberof SendSmsDto
     */
    'message': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendSmsDto
     */
    'userIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendSmsDto
     */
    'facilityId': string;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SendSmsDto
     */
    'filters'?: Array<Filter>;
    /**
     * 
     * @type {boolean}
     * @memberof SendSmsDto
     */
    'senderIsEasyJacket'?: boolean;
}
/**
 * 
 * @export
 * @interface SessionResponse
 */
export interface SessionResponse {
    /**
     * 
     * @type {AuthUser}
     * @memberof SessionResponse
     */
    'ok'?: AuthUser;
    /**
     * 
     * @type {SessionResponseError}
     * @memberof SessionResponse
     */
    'error'?: SessionResponseError;
}
/**
 * 
 * @export
 * @interface SessionResponseError
 */
export interface SessionResponseError {
    /**
     * 
     * @type {SessionResponseErrorGrandidObject}
     * @memberof SessionResponseError
     */
    'grandidObject': SessionResponseErrorGrandidObject;
}
/**
 * 
 * @export
 * @interface SessionResponseErrorGrandidObject
 */
export interface SessionResponseErrorGrandidObject {
    /**
     * 
     * @type {string}
     * @memberof SessionResponseErrorGrandidObject
     */
    'code'?: string;
    /**
     * 
     * @type {SessionResponseErrorGrandidObjectMessage}
     * @memberof SessionResponseErrorGrandidObject
     */
    'message'?: SessionResponseErrorGrandidObjectMessage;
    /**
     * 
     * @type {string}
     * @memberof SessionResponseErrorGrandidObject
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionResponseErrorGrandidObject
     */
    'QRCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionResponseErrorGrandidObject
     */
    'autoStartToken'?: string;
}
/**
 * 
 * @export
 * @interface SessionResponseErrorGrandidObjectMessage
 */
export interface SessionResponseErrorGrandidObjectMessage {
    /**
     * 
     * @type {string}
     * @memberof SessionResponseErrorGrandidObjectMessage
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionResponseErrorGrandidObjectMessage
     */
    'hintCode'?: string;
}
/**
 * 
 * @export
 * @interface ShortUrl
 */
export interface ShortUrl {
    /**
     * 
     * @type {string}
     * @memberof ShortUrl
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ShortUrl
     */
    'originalUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ShortUrl
     */
    'shortCode': string;
    /**
     * 
     * @type {number}
     * @memberof ShortUrl
     */
    'interactions': number;
    /**
     * 
     * @type {string}
     * @memberof ShortUrl
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShortUrl
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShortUrl
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof ShortUrl
     */
    'bulkSMSId': string;
}
/**
 * 
 * @export
 * @interface SignInAdminPhoneDto
 */
export interface SignInAdminPhoneDto {
    /**
     * 
     * @type {string}
     * @memberof SignInAdminPhoneDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof SignInAdminPhoneDto
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface Slot
 */
export interface Slot {
    /**
     * 
     * @type {string}
     * @memberof Slot
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Slot
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Slot
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Slot
     */
    'slotNumber': number;
    /**
     * 
     * @type {boolean}
     * @memberof Slot
     */
    'reserved': boolean;
    /**
     * 
     * @type {string}
     * @memberof Slot
     */
    'userId': string | null;
    /**
     * 
     * @type {string}
     * @memberof Slot
     */
    'zoneId': string;
    /**
     * 
     * @type {boolean}
     * @memberof Slot
     */
    'hasBag': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Slot
     */
    'checkedIn': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Slot
     */
    'disabled': boolean;
    /**
     * 
     * @type {Array<AdditionalSlotUsers>}
     * @memberof Slot
     */
    'additionalSlotUsers': Array<AdditionalSlotUsers>;
}
/**
 * 
 * @export
 * @interface SlotWithZone
 */
export interface SlotWithZone {
    /**
     * 
     * @type {string}
     * @memberof SlotWithZone
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SlotWithZone
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SlotWithZone
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof SlotWithZone
     */
    'slotNumber': number;
    /**
     * 
     * @type {boolean}
     * @memberof SlotWithZone
     */
    'reserved': boolean;
    /**
     * 
     * @type {string}
     * @memberof SlotWithZone
     */
    'userId': string | null;
    /**
     * 
     * @type {string}
     * @memberof SlotWithZone
     */
    'zoneId': string;
    /**
     * 
     * @type {boolean}
     * @memberof SlotWithZone
     */
    'hasBag': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SlotWithZone
     */
    'checkedIn': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SlotWithZone
     */
    'disabled': boolean;
    /**
     * 
     * @type {Array<AdditionalSlotUsers>}
     * @memberof SlotWithZone
     */
    'additionalSlotUsers': Array<AdditionalSlotUsers>;
    /**
     * 
     * @type {Zone}
     * @memberof SlotWithZone
     */
    'zone': Zone;
}
/**
 * 
 * @export
 * @interface Sms
 */
export interface Sms {
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'to': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'errorCode': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'errorMessage': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'bulkSMSId': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof Sms
     */
    'adminUserId': string;
    /**
     * 
     * @type {AdminUserWithoutFacility}
     * @memberof Sms
     */
    'adminUser': AdminUserWithoutFacility;
    /**
     * 
     * @type {Facility}
     * @memberof Sms
     */
    'facility': Facility;
}
/**
 * 
 * @export
 * @interface Sum
 */
export interface Sum {
    /**
     * 
     * @type {number}
     * @memberof Sum
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface SwishCallbackBody
 */
export interface SwishCallbackBody {
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'payeePaymentReference': string;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'paymentReference': string;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'callbackUrl': string;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'payerAlias': string;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'payeeAlias': string;
    /**
     * 
     * @type {number}
     * @memberof SwishCallbackBody
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'status': SwishCallbackBodyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'dateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'datePaid': string;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'errorCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof SwishCallbackBody
     */
    'errorMessage': string | null;
}

export const SwishCallbackBodyStatusEnum = {
    Paid: 'PAID',
    Declined: 'DECLINED',
    Error: 'ERROR',
    Cancelled: 'CANCELLED'
} as const;

export type SwishCallbackBodyStatusEnum = typeof SwishCallbackBodyStatusEnum[keyof typeof SwishCallbackBodyStatusEnum];

/**
 * 
 * @export
 * @interface SwishPaymentRequest
 */
export interface SwishPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof SwishPaymentRequest
     */
    'swishToken': string;
    /**
     * 
     * @type {string}
     * @memberof SwishPaymentRequest
     */
    'swishPaymentId': string;
    /**
     * 
     * @type {string}
     * @memberof SwishPaymentRequest
     */
    'paymentId': string;
    /**
     * 
     * @type {string}
     * @memberof SwishPaymentRequest
     */
    'statusCheckToken': string;
}
/**
 * 
 * @export
 * @interface SwishPaymentRequestBase
 */
export interface SwishPaymentRequestBase {
    /**
     * 
     * @type {string}
     * @memberof SwishPaymentRequestBase
     */
    'swishToken': string;
    /**
     * 
     * @type {string}
     * @memberof SwishPaymentRequestBase
     */
    'swishPaymentId': string;
}
/**
 * 
 * @export
 * @interface SwishRefund
 */
export interface SwishRefund {
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'payerPaymentReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'originalPaymentReference': string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'paymentReference': string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'callbackUrl': string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'payerAlias': string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'payeeAlias': string;
    /**
     * 
     * @type {number}
     * @memberof SwishRefund
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'dateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'datePaid': string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'additionalInformation'?: string;
    /**
     * 
     * @type {string}
     * @memberof SwishRefund
     */
    'errorCode'?: string;
}
/**
 * 
 * @export
 * @interface SwishStatus
 */
export interface SwishStatus {
    /**
     * 
     * @type {string}
     * @memberof SwishStatus
     */
    'status': SwishStatusStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SwishStatus
     */
    'swishPaymentId': string;
    /**
     * 
     * @type {Payment}
     * @memberof SwishStatus
     */
    'payment'?: Payment;
    /**
     * 
     * @type {string}
     * @memberof SwishStatus
     */
    'accessToken'?: string;
}

export const SwishStatusStatusEnum = {
    Created: 'CREATED',
    Paid: 'PAID',
    Canceled: 'CANCELED',
    Error: 'ERROR',
    Declined: 'DECLINED'
} as const;

export type SwishStatusStatusEnum = typeof SwishStatusStatusEnum[keyof typeof SwishStatusStatusEnum];

/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'consumedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'paymentId': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'consumedByAdminId': string;
}
/**
 * 
 * @export
 * @interface UnreserveDto
 */
export interface UnreserveDto {
    /**
     * 
     * @type {string}
     * @memberof UnreserveDto
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface UpdateAdminUserDto
 */
export interface UpdateAdminUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'currentZoneId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'role'?: UpdateAdminUserDtoRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    'facilityId'?: string;
}

export const UpdateAdminUserDtoRoleEnum = {
    FacilityAdmin: 'FACILITY_ADMIN',
    WardrobeAdmin: 'WARDROBE_ADMIN'
} as const;

export type UpdateAdminUserDtoRoleEnum = typeof UpdateAdminUserDtoRoleEnum[keyof typeof UpdateAdminUserDtoRoleEnum];

/**
 * 
 * @export
 * @interface UpdateAdminUserFacilityDto
 */
export interface UpdateAdminUserFacilityDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserFacilityDto
     */
    'facilityId': string;
}
/**
 * 
 * @export
 * @interface UpdateCompanyDto
 */
export interface UpdateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'bankgiro'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRefundRequestDto
 */
export interface UpdateRefundRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRefundRequestDto
     */
    'refundStatus': UpdateRefundRequestDtoRefundStatusEnum;
}

export const UpdateRefundRequestDtoRefundStatusEnum = {
    Requested: 'REQUESTED',
    Pending: 'PENDING',
    Failed: 'FAILED',
    Approved: 'APPROVED',
    Denied: 'DENIED'
} as const;

export type UpdateRefundRequestDtoRefundStatusEnum = typeof UpdateRefundRequestDtoRefundStatusEnum[keyof typeof UpdateRefundRequestDtoRefundStatusEnum];

/**
 * 
 * @export
 * @interface UpdateSlotDto
 */
export interface UpdateSlotDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSlotDto
     */
    'disabled': boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'gender'?: UpdateUserDtoGenderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'allowCommunication'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'otp'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'otpExpiry'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'birthdate'?: string;
}

export const UpdateUserDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;

export type UpdateUserDtoGenderEnum = typeof UpdateUserDtoGenderEnum[keyof typeof UpdateUserDtoGenderEnum];

/**
 * 
 * @export
 * @interface UpdateZoneDto
 */
export interface UpdateZoneDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateZoneDto
     */
    'slotStart': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateZoneDto
     */
    'slotEnd': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateZoneDto
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'allowCommunication': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'ssn': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'otp': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'otpExpiry': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'gender': UserGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'birthdate': string | null;
}

export const UserGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;

export type UserGenderEnum = typeof UserGenderEnum[keyof typeof UserGenderEnum];

/**
 * 
 * @export
 * @interface UserWithSlots
 */
export interface UserWithSlots {
    /**
     * 
     * @type {boolean}
     * @memberof UserWithSlots
     */
    'allowCommunication': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWithSlots
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserWithSlots
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserWithSlots
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserWithSlots
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserWithSlots
     */
    'ssn': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithSlots
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithSlots
     */
    'otp': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithSlots
     */
    'otpExpiry': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithSlots
     */
    'gender': UserWithSlotsGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UserWithSlots
     */
    'birthdate': string | null;
    /**
     * 
     * @type {Array<Slot>}
     * @memberof UserWithSlots
     */
    'slots': Array<Slot>;
}

export const UserWithSlotsGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;

export type UserWithSlotsGenderEnum = typeof UserWithSlotsGenderEnum[keyof typeof UserWithSlotsGenderEnum];

/**
 * 
 * @export
 * @interface ValidateDto
 */
export interface ValidateDto {
    /**
     * 
     * @type {string}
     * @memberof ValidateDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ValidateDto
     */
    'facilityId': string;
}
/**
 * 
 * @export
 * @interface Zone
 */
export interface Zone {
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'name': ZoneNameEnum;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'type': ZoneTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Zone
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof Zone
     */
    'color'?: string;
}

export const ZoneNameEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G',
    H: 'H',
    I: 'I',
    J: 'J',
    K: 'K',
    L: 'L',
    M: 'M',
    N: 'N',
    O: 'O',
    P: 'P',
    Q: 'Q',
    R: 'R',
    S: 'S',
    T: 'T',
    U: 'U',
    V: 'V',
    X: 'X',
    Y: 'Y',
    Z: 'Z',
    W: 'W',
    Archive: 'ARCHIVE',
    Bag: 'BAG'
} as const;

export type ZoneNameEnum = typeof ZoneNameEnum[keyof typeof ZoneNameEnum];
export const ZoneTypeEnum = {
    Archive: 'ARCHIVE',
    Jacket: 'JACKET',
    Bag: 'BAG'
} as const;

export type ZoneTypeEnum = typeof ZoneTypeEnum[keyof typeof ZoneTypeEnum];

/**
 * 
 * @export
 * @interface ZoneWithData
 */
export interface ZoneWithData {
    /**
     * 
     * @type {string}
     * @memberof ZoneWithData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithData
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithData
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithData
     */
    'name': ZoneWithDataNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithData
     */
    'type': ZoneWithDataTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ZoneWithData
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithData
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithData
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof ZoneWithData
     */
    'occupiedSlots': number;
    /**
     * 
     * @type {number}
     * @memberof ZoneWithData
     */
    'totalSlots': number;
    /**
     * 
     * @type {number}
     * @memberof ZoneWithData
     */
    'activeAdminUsers': number;
}

export const ZoneWithDataNameEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G',
    H: 'H',
    I: 'I',
    J: 'J',
    K: 'K',
    L: 'L',
    M: 'M',
    N: 'N',
    O: 'O',
    P: 'P',
    Q: 'Q',
    R: 'R',
    S: 'S',
    T: 'T',
    U: 'U',
    V: 'V',
    X: 'X',
    Y: 'Y',
    Z: 'Z',
    W: 'W',
    Archive: 'ARCHIVE',
    Bag: 'BAG'
} as const;

export type ZoneWithDataNameEnum = typeof ZoneWithDataNameEnum[keyof typeof ZoneWithDataNameEnum];
export const ZoneWithDataTypeEnum = {
    Archive: 'ARCHIVE',
    Jacket: 'JACKET',
    Bag: 'BAG'
} as const;

export type ZoneWithDataTypeEnum = typeof ZoneWithDataTypeEnum[keyof typeof ZoneWithDataTypeEnum];

/**
 * 
 * @export
 * @interface ZoneWithSlots
 */
export interface ZoneWithSlots {
    /**
     * 
     * @type {string}
     * @memberof ZoneWithSlots
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithSlots
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithSlots
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithSlots
     */
    'name': ZoneWithSlotsNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithSlots
     */
    'type': ZoneWithSlotsTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ZoneWithSlots
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithSlots
     */
    'facilityId': string;
    /**
     * 
     * @type {string}
     * @memberof ZoneWithSlots
     */
    'color'?: string;
    /**
     * 
     * @type {Array<Slot>}
     * @memberof ZoneWithSlots
     */
    'slots': Array<Slot>;
}

export const ZoneWithSlotsNameEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G',
    H: 'H',
    I: 'I',
    J: 'J',
    K: 'K',
    L: 'L',
    M: 'M',
    N: 'N',
    O: 'O',
    P: 'P',
    Q: 'Q',
    R: 'R',
    S: 'S',
    T: 'T',
    U: 'U',
    V: 'V',
    X: 'X',
    Y: 'Y',
    Z: 'Z',
    W: 'W',
    Archive: 'ARCHIVE',
    Bag: 'BAG'
} as const;

export type ZoneWithSlotsNameEnum = typeof ZoneWithSlotsNameEnum[keyof typeof ZoneWithSlotsNameEnum];
export const ZoneWithSlotsTypeEnum = {
    Archive: 'ARCHIVE',
    Jacket: 'JACKET',
    Bag: 'BAG'
} as const;

export type ZoneWithSlotsTypeEnum = typeof ZoneWithSlotsTypeEnum[keyof typeof ZoneWithSlotsTypeEnum];


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAdminUserDto} createAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerCreate: async (createAdminUserDto: CreateAdminUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdminUserDto' is not null or undefined
            assertParamExists('adminUsersControllerCreate', 'createAdminUserDto', createAdminUserDto)
            const localVarPath = `/admin_users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdminUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWardrobeAdminDto} createWardrobeAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerCreateWardrobeAdmin: async (createWardrobeAdminDto: CreateWardrobeAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWardrobeAdminDto' is not null or undefined
            assertParamExists('adminUsersControllerCreateWardrobeAdmin', 'createWardrobeAdminDto', createWardrobeAdminDto)
            const localVarPath = `/admin_users/create-wardrobe-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWardrobeAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [facilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerFindAll: async (facilityId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin_users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerFindMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin_users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUsersControllerFindOne', 'id', id)
            const localVarPath = `/admin_users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUsersControllerRemove', 'id', id)
            const localVarPath = `/admin_users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdminUserDto} updateAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerUpdate: async (id: string, updateAdminUserDto: UpdateAdminUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUsersControllerUpdate', 'id', id)
            // verify required parameter 'updateAdminUserDto' is not null or undefined
            assertParamExists('adminUsersControllerUpdate', 'updateAdminUserDto', updateAdminUserDto)
            const localVarPath = `/admin_users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAdminUserFacilityDto} updateAdminUserFacilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerUpdateFacility: async (updateAdminUserFacilityDto: UpdateAdminUserFacilityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAdminUserFacilityDto' is not null or undefined
            assertParamExists('adminUsersControllerUpdateFacility', 'updateAdminUserFacilityDto', updateAdminUserFacilityDto)
            const localVarPath = `/admin_users/facility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminUserFacilityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerTestError: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetSession: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authControllerGetSession', 'id', id)
            const localVarPath = `/auth/user/bank-id/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InitAdminPhoneDto} initAdminPhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInitAdminPhoneSignIn: async (initAdminPhoneDto: InitAdminPhoneDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initAdminPhoneDto' is not null or undefined
            assertParamExists('authControllerInitAdminPhoneSignIn', 'initAdminPhoneDto', initAdminPhoneDto)
            const localVarPath = `/auth/admin/phone/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initAdminPhoneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InitUserPhoneSignInDto} initUserPhoneSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInitUserPhoneSignIn: async (initUserPhoneSignInDto: InitUserPhoneSignInDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initUserPhoneSignInDto' is not null or undefined
            assertParamExists('authControllerInitUserPhoneSignIn', 'initUserPhoneSignInDto', initUserPhoneSignInDto)
            const localVarPath = `/auth/user/phone/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initUserPhoneSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InitUserPhoneSignInDto} initUserPhoneSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInitUserPhoneSignInByAdmin: async (initUserPhoneSignInDto: InitUserPhoneSignInDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initUserPhoneSignInDto' is not null or undefined
            assertParamExists('authControllerInitUserPhoneSignInByAdmin', 'initUserPhoneSignInDto', initUserPhoneSignInDto)
            const localVarPath = `/auth/user/phone/init-by-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initUserPhoneSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInitUserSignIn: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/user/bank-id/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignInAdminPhoneDto} signInAdminPhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInAdminByPhone: async (signInAdminPhoneDto: SignInAdminPhoneDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInAdminPhoneDto' is not null or undefined
            assertParamExists('authControllerSignInAdminByPhone', 'signInAdminPhoneDto', signInAdminPhoneDto)
            const localVarPath = `/auth/admin/phone/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInAdminPhoneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/user/phone/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTestRateLimit: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/test-rate-limit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerCreate: async (createCompanyDto: CreateCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyDto' is not null or undefined
            assertParamExists('companiesControllerCreate', 'createCompanyDto', createCompanyDto)
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerFindOne', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerRemove', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdate: async (id: string, updateCompanyDto: UpdateCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerUpdate', 'id', id)
            // verify required parameter 'updateCompanyDto' is not null or undefined
            assertParamExists('companiesControllerUpdate', 'updateCompanyDto', updateCompanyDto)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateReportDto} createReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerCreateTransactionsReport: async (createReportDto: CreateReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReportDto' is not null or undefined
            assertParamExists('dashboardControllerCreateTransactionsReport', 'createReportDto', createReportDto)
            const localVarPath = `/dashboard/reports/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateReportDto} createReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerCreateZReport: async (createReportDto: CreateReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReportDto' is not null or undefined
            assertParamExists('dashboardControllerCreateZReport', 'createReportDto', createReportDto)
            const localVarPath = `/dashboard/reports/z-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [gender] 
         * @param {string} [age] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerExportUsersForFacilityToCsv: async (facilityId: string, startDate: string, endDate: string, gender?: string, age?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('dashboardControllerExportUsersForFacilityToCsv', 'facilityId', facilityId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('dashboardControllerExportUsersForFacilityToCsv', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('dashboardControllerExportUsersForFacilityToCsv', 'endDate', endDate)
            const localVarPath = `/dashboard/users/{facilityId}/export-csv`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (gender !== undefined) {
                localVarQueryParameter['gender'] = gender;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetFacilityEvents: async (facilityId: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('dashboardControllerGetFacilityEvents', 'facilityId', facilityId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('dashboardControllerGetFacilityEvents', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('dashboardControllerGetFacilityEvents', 'endDate', endDate)
            const localVarPath = `/dashboard/events/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} endDate 
         * @param {string} startDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetOverview: async (endDate: string, startDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('dashboardControllerGetOverview', 'endDate', endDate)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('dashboardControllerGetOverview', 'startDate', startDate)
            const localVarPath = `/dashboard/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateReportDto} createReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetReportData: async (createReportDto: CreateReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReportDto' is not null or undefined
            assertParamExists('dashboardControllerGetReportData', 'createReportDto', createReportDto)
            const localVarPath = `/dashboard/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [gender] 
         * @param {string} [age] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetUsersForFacility: async (facilityId: string, startDate: string, endDate: string, gender?: string, age?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('dashboardControllerGetUsersForFacility', 'facilityId', facilityId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('dashboardControllerGetUsersForFacility', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('dashboardControllerGetUsersForFacility', 'endDate', endDate)
            const localVarPath = `/dashboard/users/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (gender !== undefined) {
                localVarQueryParameter['gender'] = gender;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {string} adminUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerRemoveAdminUserFromFacility: async (facilityId: string, adminUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('dashboardControllerRemoveAdminUserFromFacility', 'facilityId', facilityId)
            // verify required parameter 'adminUserId' is not null or undefined
            assertParamExists('dashboardControllerRemoveAdminUserFromFacility', 'adminUserId', adminUserId)
            const localVarPath = `/dashboard/facility/{facilityId}/remove-admin-user/{adminUserId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)))
                .replace(`{${"adminUserId"}}`, encodeURIComponent(String(adminUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [superFacilityId] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerStatistics: async (superFacilityId?: string, endDate?: string, startDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (superFacilityId !== undefined) {
                localVarQueryParameter['superFacilityId'] = superFacilityId;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerTestSocketUpdate: async (facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('dashboardControllerTestSocketUpdate', 'facilityId', facilityId)
            const localVarPath = `/dashboard/socket-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerTriggerSlackReportForDate: async (date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/reports/slack/trigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GatewayAPICallbackBody} gatewayAPICallbackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSmsControllerCallback: async (gatewayAPICallbackBody: GatewayAPICallbackBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gatewayAPICallbackBody' is not null or undefined
            assertParamExists('dashboardSmsControllerCallback', 'gatewayAPICallbackBody', gatewayAPICallbackBody)
            const localVarPath = `/dashboard/sms/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gatewayAPICallbackBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSmsControllerGetBulkSmsForFacility: async (facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('dashboardSmsControllerGetBulkSmsForFacility', 'facilityId', facilityId)
            const localVarPath = `/dashboard/sms/bulk-sms-for-facility/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSmsControllerGetSmsForUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('dashboardSmsControllerGetSmsForUser', 'userId', userId)
            const localVarPath = `/dashboard/sms/sms-for-user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendSmsDto} sendSmsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSmsControllerSendBulkSMS: async (sendSmsDto: SendSmsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendSmsDto' is not null or undefined
            assertParamExists('dashboardSmsControllerSendBulkSMS', 'sendSmsDto', sendSmsDto)
            const localVarPath = `/dashboard/sms/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendSmsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendSmsDto} sendSmsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSmsControllerSendSMSToUser: async (sendSmsDto: SendSmsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendSmsDto' is not null or undefined
            assertParamExists('dashboardSmsControllerSendSMSToUser', 'sendSmsDto', sendSmsDto)
            const localVarPath = `/dashboard/sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendSmsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ALL' | 'ME'} [scope] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsControllerGetEventsByAdminUser: async (scope?: 'ALL' | 'ME', limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsControllerGetEventsBySlotId: async (slotId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotId' is not null or undefined
            assertParamExists('eventsControllerGetEventsBySlotId', 'slotId', slotId)
            const localVarPath = `/events/by-slot/{slotId}`
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {'ALL' | 'SELECTED_FACILITY'} [scope] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsControllerGetEventsByUserId: async (userId: string, scope?: 'ALL' | 'SELECTED_FACILITY', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('eventsControllerGetEventsByUserId', 'userId', userId)
            const localVarPath = `/events/by-user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateFacilityDto} createFacilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerCreate: async (createFacilityDto: CreateFacilityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFacilityDto' is not null or undefined
            assertParamExists('facilitiesControllerCreate', 'createFacilityDto', createFacilityDto)
            const localVarPath = `/facilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFacilityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetAllFacilities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facilities/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} since 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetEntranceSummary: async (id: string, since: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('facilitiesControllerGetEntranceSummary', 'id', id)
            // verify required parameter 'since' is not null or undefined
            assertParamExists('facilitiesControllerGetEntranceSummary', 'since', since)
            const localVarPath = `/facilities/{id}/entrance-summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetFacilitiesByUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetFacilitiesForPayments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facilities/for-payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetFacilityByName: async (facilityName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityName' is not null or undefined
            assertParamExists('facilitiesControllerGetFacilityByName', 'facilityName', facilityName)
            const localVarPath = `/facilities/by-name/{facilityName}`
                .replace(`{${"facilityName"}}`, encodeURIComponent(String(facilityName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetFacilityEvents: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('facilitiesControllerGetFacilityEvents', 'id', id)
            const localVarPath = `/facilities/{id}/events`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetFacilityHistory: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('facilitiesControllerGetFacilityHistory', 'id', id)
            const localVarPath = `/facilities/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerTestUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facilities/test/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerUpdate: async (id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('facilitiesControllerUpdate', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('facilitiesControllerUpdate', 'body', body)
            const localVarPath = `/facilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} feature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureFlagControllerIsEnabled: async (feature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'feature' is not null or undefined
            assertParamExists('featureFlagControllerIsEnabled', 'feature', feature)
            const localVarPath = `/feature-flag/is-enabled/{feature}`
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} feature 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureFlagControllerIsEnabledForFacility: async (feature: string, facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'feature' is not null or undefined
            assertParamExists('featureFlagControllerIsEnabledForFacility', 'feature', feature)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('featureFlagControllerIsEnabledForFacility', 'facilityId', facilityId)
            const localVarPath = `/feature-flag/is-enabled/{feature}/facility/{facilityId}`
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} feature 
         * @param {string} paymentCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureFlagControllerIsEnabledForPaymentCode: async (feature: string, paymentCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'feature' is not null or undefined
            assertParamExists('featureFlagControllerIsEnabledForPaymentCode', 'feature', feature)
            // verify required parameter 'paymentCode' is not null or undefined
            assertParamExists('featureFlagControllerIsEnabledForPaymentCode', 'paymentCode', paymentCode)
            const localVarPath = `/feature-flag/is-enabled/{feature}/payment-code/{paymentCode}`
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)))
                .replace(`{${"paymentCode"}}`, encodeURIComponent(String(paymentCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerActivePaymentsForUserByAdmin: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('paymentsControllerActivePaymentsForUserByAdmin', 'userId', userId)
            const localVarPath = `/payments/active-for-user-by-admin/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentIntentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerConfirmPaymentIntent: async (paymentIntentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentIntentId' is not null or undefined
            assertParamExists('paymentsControllerConfirmPaymentIntent', 'paymentIntentId', paymentIntentId)
            const localVarPath = `/payments/confirm-intent/{paymentIntentId}`
                .replace(`{${"paymentIntentId"}}`, encodeURIComponent(String(paymentIntentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerConfirmSession: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('paymentsControllerConfirmSession', 'sessionId', sessionId)
            const localVarPath = `/payments/confirm-session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerFindAllByUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('paymentsControllerFindAllByUser', 'userId', userId)
            const localVarPath = `/payments/by-user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentsControllerFindOne', 'id', id)
            const localVarPath = `/payments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerFindOneForUser: async (paymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('paymentsControllerFindOneForUser', 'paymentId', paymentId)
            const localVarPath = `/payments/receipt/user/{paymentId}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerFindPaymentsForUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {InitPaymentDto} initPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerInitPaymentForFacility: async (facilityId: string, initPaymentDto: InitPaymentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('paymentsControllerInitPaymentForFacility', 'facilityId', facilityId)
            // verify required parameter 'initPaymentDto' is not null or undefined
            assertParamExists('paymentsControllerInitPaymentForFacility', 'initPaymentDto', initPaymentDto)
            const localVarPath = `/payments/facility/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initPaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {InitPaymentDto} initPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerInitPaymentIntentForFacility: async (facilityId: string, initPaymentDto: InitPaymentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('paymentsControllerInitPaymentIntentForFacility', 'facilityId', facilityId)
            // verify required parameter 'initPaymentDto' is not null or undefined
            assertParamExists('paymentsControllerInitPaymentIntentForFacility', 'initPaymentDto', initPaymentDto)
            const localVarPath = `/payments/facility/{facilityId}/intent`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initPaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerInitSwishByAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments/swish/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {InitPaymentDto} initPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerInitSwishForFacility: async (facilityId: string, initPaymentDto: InitPaymentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('paymentsControllerInitSwishForFacility', 'facilityId', facilityId)
            // verify required parameter 'initPaymentDto' is not null or undefined
            assertParamExists('paymentsControllerInitSwishForFacility', 'initPaymentDto', initPaymentDto)
            const localVarPath = `/payments/facility/{facilityId}/swish`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initPaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentIntentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerIntentStatus: async (paymentIntentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentIntentId' is not null or undefined
            assertParamExists('paymentsControllerIntentStatus', 'paymentIntentId', paymentIntentId)
            const localVarPath = `/payments/status/intent/{paymentIntentId}`
                .replace(`{${"paymentIntentId"}}`, encodeURIComponent(String(paymentIntentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSessionStatus: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('paymentsControllerSessionStatus', 'sessionId', sessionId)
            const localVarPath = `/payments/status/session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SwishCallbackBody} swishCallbackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSwishCallback: async (swishCallbackBody: SwishCallbackBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'swishCallbackBody' is not null or undefined
            assertParamExists('paymentsControllerSwishCallback', 'swishCallbackBody', swishCallbackBody)
            const localVarPath = `/payments/swish/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swishCallbackBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SwishCallbackBody} swishCallbackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSwishCallbackQrCode: async (swishCallbackBody: SwishCallbackBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'swishCallbackBody' is not null or undefined
            assertParamExists('paymentsControllerSwishCallbackQrCode', 'swishCallbackBody', swishCallbackBody)
            const localVarPath = `/payments/swish/callback-qr-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swishCallbackBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSwishStatus: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('paymentsControllerSwishStatus', 'token', token)
            const localVarPath = `/payments/swish/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} swishPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSwishStatusByAdmin: async (swishPaymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'swishPaymentId' is not null or undefined
            assertParamExists('paymentsControllerSwishStatusByAdmin', 'swishPaymentId', swishPaymentId)
            const localVarPath = `/payments/swish/status/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (swishPaymentId !== undefined) {
                localVarQueryParameter['swishPaymentId'] = swishPaymentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerTriggerPaymentForUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('paymentsControllerTriggerPaymentForUser', 'userId', userId)
            const localVarPath = `/payments/trigger/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerRedirectToOriginal: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('redirectsControllerRedirectToOriginal', 'code', code)
            const localVarPath = `/redirects/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRefundRequestDto} createRefundRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerCreate: async (createRefundRequestDto: CreateRefundRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRefundRequestDto' is not null or undefined
            assertParamExists('refundRequestsControllerCreate', 'createRefundRequestDto', createRefundRequestDto)
            const localVarPath = `/refund-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRefundRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/refund-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerFindAllByFacility: async (facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('refundRequestsControllerFindAllByFacility', 'facilityId', facilityId)
            const localVarPath = `/refund-requests/by-facility/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerFindAllByUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('refundRequestsControllerFindAllByUser', 'userId', userId)
            const localVarPath = `/refund-requests/by-user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refundRequestsControllerFindOne', 'id', id)
            const localVarPath = `/refund-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRefundRequestDto} createRefundRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerRefundByAdmin: async (createRefundRequestDto: CreateRefundRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRefundRequestDto' is not null or undefined
            assertParamExists('refundRequestsControllerRefundByAdmin', 'createRefundRequestDto', createRefundRequestDto)
            const localVarPath = `/refund-requests/refund-by-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRefundRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SwishRefund} swishRefund 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerSwishCallback: async (swishRefund: SwishRefund, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'swishRefund' is not null or undefined
            assertParamExists('refundRequestsControllerSwishCallback', 'swishRefund', swishRefund)
            const localVarPath = `/refund-requests/swish/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swishRefund, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRefundRequestDto} updateRefundRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerUpdate: async (id: string, updateRefundRequestDto: UpdateRefundRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refundRequestsControllerUpdate', 'id', id)
            // verify required parameter 'updateRefundRequestDto' is not null or undefined
            assertParamExists('refundRequestsControllerUpdate', 'updateRefundRequestDto', updateRefundRequestDto)
            const localVarPath = `/refund-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRefundRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slotId 
         * @param {AddAdditionalUserToSlotDto} addAdditionalUserToSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerAddAdditionalUserToSlot: async (slotId: string, addAdditionalUserToSlotDto: AddAdditionalUserToSlotDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotId' is not null or undefined
            assertParamExists('slotsControllerAddAdditionalUserToSlot', 'slotId', slotId)
            // verify required parameter 'addAdditionalUserToSlotDto' is not null or undefined
            assertParamExists('slotsControllerAddAdditionalUserToSlot', 'addAdditionalUserToSlotDto', addAdditionalUserToSlotDto)
            const localVarPath = `/slots/check-in/{slotId}/add-additional-user`
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAdditionalUserToSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ArchiveDto} archiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerArchive: async (archiveDto: ArchiveDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'archiveDto' is not null or undefined
            assertParamExists('slotsControllerArchive', 'archiveDto', archiveDto)
            const localVarPath = `/slots/archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archiveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerArchived: async (facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('slotsControllerArchived', 'facilityId', facilityId)
            const localVarPath = `/slots/archived/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckInDto} checkInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerCheckIn: async (checkInDto: CheckInDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkInDto' is not null or undefined
            assertParamExists('slotsControllerCheckIn', 'checkInDto', checkInDto)
            const localVarPath = `/slots/check-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckOutDto} checkOutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerCheckOut: async (checkOutDto: CheckOutDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkOutDto' is not null or undefined
            assertParamExists('slotsControllerCheckOut', 'checkOutDto', checkOutDto)
            const localVarPath = `/slots/check-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkOutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slotId 
         * @param {CheckOutDto} checkOutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerCheckOutById: async (slotId: string, checkOutDto: CheckOutDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotId' is not null or undefined
            assertParamExists('slotsControllerCheckOutById', 'slotId', slotId)
            // verify required parameter 'checkOutDto' is not null or undefined
            assertParamExists('slotsControllerCheckOutById', 'checkOutDto', checkOutDto)
            const localVarPath = `/slots/check-out/{slotId}`
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkOutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerGetBySlotId: async (slotId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotId' is not null or undefined
            assertParamExists('slotsControllerGetBySlotId', 'slotId', slotId)
            const localVarPath = `/slots/by-slot-id/{slotId}`
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerGetByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('slotsControllerGetByUserId', 'userId', userId)
            const localVarPath = `/slots/by-user-id/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} zoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerGetByZoneId: async (zoneId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('slotsControllerGetByZoneId', 'zoneId', zoneId)
            const localVarPath = `/slots/by-zone-id/{zoneId}`
                .replace(`{${"zoneId"}}`, encodeURIComponent(String(zoneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MoveDto} moveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerMove: async (moveDto: MoveDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moveDto' is not null or undefined
            assertParamExists('slotsControllerMove', 'moveDto', moveDto)
            const localVarPath = `/slots/move`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReserveSlotDto} reserveSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerReserve: async (reserveSlotDto: ReserveSlotDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reserveSlotDto' is not null or undefined
            assertParamExists('slotsControllerReserve', 'reserveSlotDto', reserveSlotDto)
            const localVarPath = `/slots/reserve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reserveSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slotId 
         * @param {ReserveSlotDto} reserveSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerReserveSpecificSlot: async (slotId: string, reserveSlotDto: ReserveSlotDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotId' is not null or undefined
            assertParamExists('slotsControllerReserveSpecificSlot', 'slotId', slotId)
            // verify required parameter 'reserveSlotDto' is not null or undefined
            assertParamExists('slotsControllerReserveSpecificSlot', 'reserveSlotDto', reserveSlotDto)
            const localVarPath = `/slots/reserve/{slotId}`
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reserveSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerRevertCheckout: async (eventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('slotsControllerRevertCheckout', 'eventId', eventId)
            const localVarPath = `/slots/check-out/{eventId}/revert`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerSearch: async (facilityId: string, query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('slotsControllerSearch', 'facilityId', facilityId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('slotsControllerSearch', 'query', query)
            const localVarPath = `/slots/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnreserveDto} unreserveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerUnreserve: async (unreserveDto: UnreserveDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unreserveDto' is not null or undefined
            assertParamExists('slotsControllerUnreserve', 'unreserveDto', unreserveDto)
            const localVarPath = `/slots/unreserve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unreserveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slotId 
         * @param {UnreserveDto} unreserveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerUnreserveSpecificSlot: async (slotId: string, unreserveDto: UnreserveDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotId' is not null or undefined
            assertParamExists('slotsControllerUnreserveSpecificSlot', 'slotId', slotId)
            // verify required parameter 'unreserveDto' is not null or undefined
            assertParamExists('slotsControllerUnreserveSpecificSlot', 'unreserveDto', unreserveDto)
            const localVarPath = `/slots/unreserve/{slotId}`
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unreserveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSlotDto} updateSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerUpdate: async (id: string, updateSlotDto: UpdateSlotDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('slotsControllerUpdate', 'id', id)
            // verify required parameter 'updateSlotDto' is not null or undefined
            assertParamExists('slotsControllerUpdate', 'updateSlotDto', updateSlotDto)
            const localVarPath = `/slots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ValidateDto} validateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerValidate: async (id: string, validateDto: ValidateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('slotsControllerValidate', 'id', id)
            // verify required parameter 'validateDto' is not null or undefined
            assertParamExists('slotsControllerValidate', 'validateDto', validateDto)
            const localVarPath = `/slots/{id}/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketsControllerConsume: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ticketsControllerConsume', 'id', id)
            const localVarPath = `/tickets/{id}/consume`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketsControllerResetConsumedAt: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ticketsControllerResetConsumedAt', 'id', id)
            const localVarPath = `/tickets/{id}/reset-consumed-at`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ValidateDto} validateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketsControllerValidate: async (id: string, validateDto: ValidateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ticketsControllerValidate', 'id', id)
            // verify required parameter 'validateDto' is not null or undefined
            assertParamExists('ticketsControllerValidate', 'validateDto', validateDto)
            const localVarPath = `/tickets/{id}/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} phone 
         * @param {string} otp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerConfirmAddPhone: async (id: string, phone: string, otp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerConfirmAddPhone', 'id', id)
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('usersControllerConfirmAddPhone', 'phone', phone)
            // verify required parameter 'otp' is not null or undefined
            assertParamExists('usersControllerConfirmAddPhone', 'otp', otp)
            const localVarPath = `/users/{id}/confirm-add-phone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (otp !== undefined) {
                localVarQueryParameter['otp'] = otp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate: async (createUserDto: CreateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserDto' is not null or undefined
            assertParamExists('usersControllerCreate', 'createUserDto', createUserDto)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ssn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetBySSN: async (ssn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ssn' is not null or undefined
            assertParamExists('usersControllerGetBySSN', 'ssn', ssn)
            const localVarPath = `/users/get-by-ssn/{ssn}`
                .replace(`{${"ssn"}}`, encodeURIComponent(String(ssn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetQrCodes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/qr-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerGetUserById', 'id', id)
            const localVarPath = `/users/by-id/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInitAddPhone: async (id: string, phone: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerInitAddPhone', 'id', id)
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('usersControllerInitAddPhone', 'phone', phone)
            const localVarPath = `/users/{id}/init-add-phone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerRemove', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSearch: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('usersControllerSearch', 'query', query)
            const localVarPath = `/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchByBirthdateDto} searchByBirthdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSearchByBirthdate: async (searchByBirthdateDto: SearchByBirthdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchByBirthdateDto' is not null or undefined
            assertParamExists('usersControllerSearchByBirthdate', 'searchByBirthdateDto', searchByBirthdateDto)
            const localVarPath = `/users/searchByBirthdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchByBirthdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate: async (id: string, updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerUpdate', 'id', id)
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('usersControllerUpdate', 'updateUserDto', updateUserDto)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateZoneDto} createZoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerCreate: async (createZoneDto: CreateZoneDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createZoneDto' is not null or undefined
            assertParamExists('zonesControllerCreate', 'createZoneDto', createZoneDto)
            const localVarPath = `/zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createZoneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerFindAllByFacilityId: async (facilityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('zonesControllerFindAllByFacilityId', 'facilityId', facilityId)
            const localVarPath = `/zones/by-facility-id/{facilityId}`
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerFindBySlotId: async (slotId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotId' is not null or undefined
            assertParamExists('zonesControllerFindBySlotId', 'slotId', slotId)
            const localVarPath = `/zones/by-slot-id/{slotId}`
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} zoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerFindOne: async (zoneId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('zonesControllerFindOne', 'zoneId', zoneId)
            const localVarPath = `/zones/{zoneId}`
                .replace(`{${"zoneId"}}`, encodeURIComponent(String(zoneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} zoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerFindOneWithSlots: async (zoneId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('zonesControllerFindOneWithSlots', 'zoneId', zoneId)
            const localVarPath = `/zones/{zoneId}/with-slots`
                .replace(`{${"zoneId"}}`, encodeURIComponent(String(zoneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zonesControllerRemove', 'id', id)
            const localVarPath = `/zones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateZoneDto} updateZoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerUpdate: async (id: string, updateZoneDto: UpdateZoneDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zonesControllerUpdate', 'id', id)
            // verify required parameter 'updateZoneDto' is not null or undefined
            assertParamExists('zonesControllerUpdate', 'updateZoneDto', updateZoneDto)
            const localVarPath = `/zones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateZoneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAdminUserDto} createAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerCreate(createAdminUserDto: CreateAdminUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerCreate(createAdminUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateWardrobeAdminDto} createWardrobeAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerCreateWardrobeAdmin(createWardrobeAdminDto: CreateWardrobeAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerCreateWardrobeAdmin(createWardrobeAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [facilityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerFindAll(facilityId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerFindAll(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerFindMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerFindMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdminUserDto} updateAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerUpdate(id: string, updateAdminUserDto: UpdateAdminUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerUpdate(id, updateAdminUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAdminUserFacilityDto} updateAdminUserFacilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerUpdateFacility(updateAdminUserFacilityDto: UpdateAdminUserFacilityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerUpdateFacility(updateAdminUserFacilityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerTestError(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerTestError(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetSession(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGetSession(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InitAdminPhoneDto} initAdminPhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerInitAdminPhoneSignIn(initAdminPhoneDto: InitAdminPhoneDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerInitAdminPhoneSignIn(initAdminPhoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InitUserPhoneSignInDto} initUserPhoneSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerInitUserPhoneSignIn(initUserPhoneSignInDto: InitUserPhoneSignInDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerInitUserPhoneSignIn(initUserPhoneSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InitUserPhoneSignInDto} initUserPhoneSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerInitUserPhoneSignInByAdmin(initUserPhoneSignInDto: InitUserPhoneSignInDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerInitUserPhoneSignInByAdmin(initUserPhoneSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerInitUserSignIn(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerInitUserSignIn(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSignInAdmin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSignInAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignInAdminPhoneDto} signInAdminPhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSignInAdminByPhone(signInAdminPhoneDto: SignInAdminPhoneDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSignInAdminByPhone(signInAdminPhoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSignInUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSignInUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTestRateLimit(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTestRateLimit(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerCreate(createCompanyDto: CreateCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerCreate(createCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerUpdate(id: string, updateCompanyDto: UpdateCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerUpdate(id, updateCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateReportDto} createReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerCreateTransactionsReport(createReportDto: CreateReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerCreateTransactionsReport(createReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateReportDto} createReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerCreateZReport(createReportDto: CreateReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerCreateZReport(createReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [gender] 
         * @param {string} [age] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerExportUsersForFacilityToCsv(facilityId: string, startDate: string, endDate: string, gender?: string, age?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerExportUsersForFacilityToCsv(facilityId, startDate, endDate, gender, age, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetFacilityEvents(facilityId: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventWithZoneAndSlotAndAdminUserAndUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetFacilityEvents(facilityId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} endDate 
         * @param {string} startDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetOverview(endDate: string, startDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Overview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetOverview(endDate, startDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateReportDto} createReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetReportData(createReportDto: CreateReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetReportData(createReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [gender] 
         * @param {string} [age] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetUsersForFacility(facilityId: string, startDate: string, endDate: string, gender?: string, age?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetUsersForFacility(facilityId, startDate, endDate, gender, age, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {string} adminUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerRemoveAdminUserFromFacility(facilityId: string, adminUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerRemoveAdminUserFromFacility(facilityId, adminUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [superFacilityId] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerStatistics(superFacilityId?: string, endDate?: string, startDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerStatistics(superFacilityId, endDate, startDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OverviewData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerTestSocketUpdate(facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerTestSocketUpdate(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerTriggerSlackReportForDate(date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerTriggerSlackReportForDate(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GatewayAPICallbackBody} gatewayAPICallbackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardSmsControllerCallback(gatewayAPICallbackBody: GatewayAPICallbackBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardSmsControllerCallback(gatewayAPICallbackBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardSmsControllerGetBulkSmsForFacility(facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BulkSmsWithShortUrls>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardSmsControllerGetBulkSmsForFacility(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardSmsControllerGetSmsForUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sms>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardSmsControllerGetSmsForUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendSmsDto} sendSmsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardSmsControllerSendBulkSMS(sendSmsDto: SendSmsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardSmsControllerSendBulkSMS(sendSmsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendSmsDto} sendSmsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardSmsControllerSendSMSToUser(sendSmsDto: SendSmsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardSmsControllerSendSMSToUser(sendSmsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'ALL' | 'ME'} [scope] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsControllerGetEventsByAdminUser(scope?: 'ALL' | 'ME', limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventWithZoneAndSlot>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsControllerGetEventsByAdminUser(scope, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsControllerGetEventsBySlotId(slotId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventWithZoneAndSlotAndPreviousAndNext>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsControllerGetEventsBySlotId(slotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {'ALL' | 'SELECTED_FACILITY'} [scope] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventsControllerGetEventsByUserId(userId: string, scope?: 'ALL' | 'SELECTED_FACILITY', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventWithZoneAndSlot>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventsControllerGetEventsByUserId(userId, scope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateFacilityDto} createFacilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilitiesControllerCreate(createFacilityDto: CreateFacilityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Facility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilitiesControllerCreate(createFacilityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilitiesControllerGetAllFacilities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Facility>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilitiesControllerGetAllFacilities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} since 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilitiesControllerGetEntranceSummary(id: string, since: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntranceSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilitiesControllerGetEntranceSummary(id, since, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilitiesControllerGetFacilitiesByUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Facility>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilitiesControllerGetFacilitiesByUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilitiesControllerGetFacilitiesForPayments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityForPayments>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilitiesControllerGetFacilitiesForPayments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilitiesControllerGetFacilityByName(facilityName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Facility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilitiesControllerGetFacilityByName(facilityName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilitiesControllerGetFacilityEvents(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityWithAndZonesAndSlots>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilitiesControllerGetFacilityEvents(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilitiesControllerGetFacilityHistory(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilitiesControllerGetFacilityHistory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilitiesControllerTestUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Facility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilitiesControllerTestUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facilitiesControllerUpdate(id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Facility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facilitiesControllerUpdate(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} feature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureFlagControllerIsEnabled(feature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagControllerIsEnabled(feature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} feature 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureFlagControllerIsEnabledForFacility(feature: string, facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagControllerIsEnabledForFacility(feature, facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} feature 
         * @param {string} paymentCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureFlagControllerIsEnabledForPaymentCode(feature: string, paymentCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagControllerIsEnabledForPaymentCode(feature, paymentCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerActivePaymentsForUserByAdmin(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerActivePaymentsForUserByAdmin(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} paymentIntentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerConfirmPaymentIntent(paymentIntentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIntent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerConfirmPaymentIntent(paymentIntentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerConfirmSession(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckoutSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerConfirmSession(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerFindAllByUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentWithFacilityAndReceiptAndRefundRequests>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerFindAllByUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerFindOneForUser(paymentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentWithFacilityAndReceiptAndRefundRequests>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerFindOneForUser(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerFindPaymentsForUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentWithFacility>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerFindPaymentsForUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {InitPaymentDto} initPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerInitPaymentForFacility(facilityId: string, initPaymentDto: InitPaymentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckoutSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerInitPaymentForFacility(facilityId, initPaymentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {InitPaymentDto} initPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerInitPaymentIntentForFacility(facilityId: string, initPaymentDto: InitPaymentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIntent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerInitPaymentIntentForFacility(facilityId, initPaymentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerInitSwishByAdmin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SwishPaymentRequestBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerInitSwishByAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {InitPaymentDto} initPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerInitSwishForFacility(facilityId: string, initPaymentDto: InitPaymentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SwishPaymentRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerInitSwishForFacility(facilityId, initPaymentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} paymentIntentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerIntentStatus(paymentIntentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIntent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerIntentStatus(paymentIntentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerSessionStatus(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckoutSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerSessionStatus(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SwishCallbackBody} swishCallbackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerSwishCallback(swishCallbackBody: SwishCallbackBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerSwishCallback(swishCallbackBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SwishCallbackBody} swishCallbackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerSwishCallbackQrCode(swishCallbackBody: SwishCallbackBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerSwishCallbackQrCode(swishCallbackBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerSwishStatus(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SwishStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerSwishStatus(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} swishPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerSwishStatusByAdmin(swishPaymentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SwishStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerSwishStatusByAdmin(swishPaymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerTriggerPaymentForUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerTriggerPaymentForUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectsControllerRedirectToOriginal(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectsControllerRedirectToOriginal(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateRefundRequestDto} createRefundRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundRequestsControllerCreate(createRefundRequestDto: CreateRefundRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefundRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundRequestsControllerCreate(createRefundRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundRequestsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RefundRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundRequestsControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundRequestsControllerFindAllByFacility(facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RefundRequestWithPayment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundRequestsControllerFindAllByFacility(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundRequestsControllerFindAllByUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RefundRequestWithPayment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundRequestsControllerFindAllByUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundRequestsControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefundRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundRequestsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateRefundRequestDto} createRefundRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundRequestsControllerRefundByAdmin(createRefundRequestDto: CreateRefundRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefundRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundRequestsControllerRefundByAdmin(createRefundRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SwishRefund} swishRefund 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundRequestsControllerSwishCallback(swishRefund: SwishRefund, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundRequestsControllerSwishCallback(swishRefund, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRefundRequestDto} updateRefundRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundRequestsControllerUpdate(id: string, updateRefundRequestDto: UpdateRefundRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefundRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundRequestsControllerUpdate(id, updateRefundRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slotId 
         * @param {AddAdditionalUserToSlotDto} addAdditionalUserToSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerAddAdditionalUserToSlot(slotId: string, addAdditionalUserToSlotDto: AddAdditionalUserToSlotDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotWithZone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerAddAdditionalUserToSlot(slotId, addAdditionalUserToSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ArchiveDto} archiveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerArchive(archiveDto: ArchiveDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerArchive(archiveDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerArchived(facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlotWithZone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerArchived(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckInDto} checkInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerCheckIn(checkInDto: CheckInDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Slot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerCheckIn(checkInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckOutDto} checkOutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerCheckOut(checkOutDto: CheckOutDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Slot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerCheckOut(checkOutDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slotId 
         * @param {CheckOutDto} checkOutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerCheckOutById(slotId: string, checkOutDto: CheckOutDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerCheckOutById(slotId, checkOutDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerGetBySlotId(slotId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotWithZone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerGetBySlotId(slotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerGetByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlotWithZone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerGetByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} zoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerGetByZoneId(zoneId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Slot>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerGetByZoneId(zoneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MoveDto} moveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerMove(moveDto: MoveDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerMove(moveDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReserveSlotDto} reserveSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerReserve(reserveSlotDto: ReserveSlotDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotWithZone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerReserve(reserveSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slotId 
         * @param {ReserveSlotDto} reserveSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerReserveSpecificSlot(slotId: string, reserveSlotDto: ReserveSlotDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotWithZone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerReserveSpecificSlot(slotId, reserveSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerRevertCheckout(eventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerRevertCheckout(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerSearch(facilityId: string, query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlotWithZone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerSearch(facilityId, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UnreserveDto} unreserveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerUnreserve(unreserveDto: UnreserveDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotWithZone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerUnreserve(unreserveDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slotId 
         * @param {UnreserveDto} unreserveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerUnreserveSpecificSlot(slotId: string, unreserveDto: UnreserveDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerUnreserveSpecificSlot(slotId, unreserveDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSlotDto} updateSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerUpdate(id: string, updateSlotDto: UpdateSlotDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Slot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerUpdate(id, updateSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ValidateDto} validateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsControllerValidate(id: string, validateDto: ValidateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Slot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsControllerValidate(id, validateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketsControllerConsume(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ticket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketsControllerConsume(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketsControllerResetConsumedAt(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ticket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketsControllerResetConsumedAt(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ValidateDto} validateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketsControllerValidate(id: string, validateDto: ValidateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ticket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketsControllerValidate(id, validateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} phone 
         * @param {string} otp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerConfirmAddPhone(id: string, phone: string, otp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerConfirmAddPhone(id, phone, otp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCreate(createUserDto: CreateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCreate(createUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFindMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ssn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetBySSN(ssn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetBySSN(ssn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetQrCodes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetQrCodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUserById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerInitAddPhone(id: string, phone: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerInitAddPhone(id, phone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSearch(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserWithSlots>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSearch(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchByBirthdateDto} searchByBirthdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSearchByBirthdate(searchByBirthdateDto: SearchByBirthdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSearchByBirthdate(searchByBirthdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdate(id: string, updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdate(id, updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateZoneDto} createZoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zonesControllerCreate(createZoneDto: CreateZoneDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zonesControllerCreate(createZoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zonesControllerFindAllByFacilityId(facilityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneWithData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zonesControllerFindAllByFacilityId(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zonesControllerFindBySlotId(slotId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zonesControllerFindBySlotId(slotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} zoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zonesControllerFindOne(zoneId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zonesControllerFindOne(zoneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} zoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zonesControllerFindOneWithSlots(zoneId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneWithSlots>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zonesControllerFindOneWithSlots(zoneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zonesControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zonesControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateZoneDto} updateZoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zonesControllerUpdate(id: string, updateZoneDto: UpdateZoneDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zonesControllerUpdate(id, updateZoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {DefaultApiAdminUsersControllerCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerCreate(requestParameters: DefaultApiAdminUsersControllerCreateRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUser> {
            return localVarFp.adminUsersControllerCreate(requestParameters.createAdminUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAdminUsersControllerCreateWardrobeAdminRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerCreateWardrobeAdmin(requestParameters: DefaultApiAdminUsersControllerCreateWardrobeAdminRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUser> {
            return localVarFp.adminUsersControllerCreateWardrobeAdmin(requestParameters.createWardrobeAdminDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAdminUsersControllerFindAllRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerFindAll(requestParameters: DefaultApiAdminUsersControllerFindAllRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AdminUser>> {
            return localVarFp.adminUsersControllerFindAll(requestParameters.facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerFindMe(options?: AxiosRequestConfig): AxiosPromise<AdminUser> {
            return localVarFp.adminUsersControllerFindMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAdminUsersControllerFindOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerFindOne(requestParameters: DefaultApiAdminUsersControllerFindOneRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUser> {
            return localVarFp.adminUsersControllerFindOne(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAdminUsersControllerRemoveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerRemove(requestParameters: DefaultApiAdminUsersControllerRemoveRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUser> {
            return localVarFp.adminUsersControllerRemove(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAdminUsersControllerUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerUpdate(requestParameters: DefaultApiAdminUsersControllerUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUser> {
            return localVarFp.adminUsersControllerUpdate(requestParameters.id, requestParameters.updateAdminUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAdminUsersControllerUpdateFacilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerUpdateFacility(requestParameters: DefaultApiAdminUsersControllerUpdateFacilityRequest, options?: AxiosRequestConfig): AxiosPromise<AdminUser> {
            return localVarFp.adminUsersControllerUpdateFacility(requestParameters.updateAdminUserFacilityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerTestError(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.appControllerTestError(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAuthControllerGetSessionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetSession(requestParameters: DefaultApiAuthControllerGetSessionRequest, options?: AxiosRequestConfig): AxiosPromise<SessionResponse> {
            return localVarFp.authControllerGetSession(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAuthControllerInitAdminPhoneSignInRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInitAdminPhoneSignIn(requestParameters: DefaultApiAuthControllerInitAdminPhoneSignInRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authControllerInitAdminPhoneSignIn(requestParameters.initAdminPhoneDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAuthControllerInitUserPhoneSignInRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInitUserPhoneSignIn(requestParameters: DefaultApiAuthControllerInitUserPhoneSignInRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authControllerInitUserPhoneSignIn(requestParameters.initUserPhoneSignInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAuthControllerInitUserPhoneSignInByAdminRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInitUserPhoneSignInByAdmin(requestParameters: DefaultApiAuthControllerInitUserPhoneSignInByAdminRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.authControllerInitUserPhoneSignInByAdmin(requestParameters.initUserPhoneSignInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInitUserSignIn(options?: AxiosRequestConfig): AxiosPromise<InitResponse> {
            return localVarFp.authControllerInitUserSignIn(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInAdmin(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.authControllerSignInAdmin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiAuthControllerSignInAdminByPhoneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInAdminByPhone(requestParameters: DefaultApiAuthControllerSignInAdminByPhoneRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.authControllerSignInAdminByPhone(requestParameters.signInAdminPhoneDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInUser(options?: AxiosRequestConfig): AxiosPromise<AuthUser> {
            return localVarFp.authControllerSignInUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTestRateLimit(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.authControllerTestRateLimit(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCompaniesControllerCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerCreate(requestParameters: DefaultApiCompaniesControllerCreateRequest, options?: AxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.companiesControllerCreate(requestParameters.createCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindAll(options?: AxiosRequestConfig): AxiosPromise<Array<Company>> {
            return localVarFp.companiesControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCompaniesControllerFindOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindOne(requestParameters: DefaultApiCompaniesControllerFindOneRequest, options?: AxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.companiesControllerFindOne(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCompaniesControllerRemoveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerRemove(requestParameters: DefaultApiCompaniesControllerRemoveRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.companiesControllerRemove(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCompaniesControllerUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdate(requestParameters: DefaultApiCompaniesControllerUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.companiesControllerUpdate(requestParameters.id, requestParameters.updateCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerCreateTransactionsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerCreateTransactionsReport(requestParameters: DefaultApiDashboardControllerCreateTransactionsReportRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.dashboardControllerCreateTransactionsReport(requestParameters.createReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerCreateZReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerCreateZReport(requestParameters: DefaultApiDashboardControllerCreateZReportRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.dashboardControllerCreateZReport(requestParameters.createReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerExportUsersForFacilityToCsvRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerExportUsersForFacilityToCsv(requestParameters: DefaultApiDashboardControllerExportUsersForFacilityToCsvRequest, options?: AxiosRequestConfig): AxiosPromise<Array<User>> {
            return localVarFp.dashboardControllerExportUsersForFacilityToCsv(requestParameters.facilityId, requestParameters.startDate, requestParameters.endDate, requestParameters.gender, requestParameters.age, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerGetFacilityEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetFacilityEvents(requestParameters: DefaultApiDashboardControllerGetFacilityEventsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<EventWithZoneAndSlotAndAdminUserAndUser>> {
            return localVarFp.dashboardControllerGetFacilityEvents(requestParameters.facilityId, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerGetOverviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetOverview(requestParameters: DefaultApiDashboardControllerGetOverviewRequest, options?: AxiosRequestConfig): AxiosPromise<Overview> {
            return localVarFp.dashboardControllerGetOverview(requestParameters.endDate, requestParameters.startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerGetReportDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetReportData(requestParameters: DefaultApiDashboardControllerGetReportDataRequest, options?: AxiosRequestConfig): AxiosPromise<ReportData> {
            return localVarFp.dashboardControllerGetReportData(requestParameters.createReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerGetUsersForFacilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetUsersForFacility(requestParameters: DefaultApiDashboardControllerGetUsersForFacilityRequest, options?: AxiosRequestConfig): AxiosPromise<Array<User>> {
            return localVarFp.dashboardControllerGetUsersForFacility(requestParameters.facilityId, requestParameters.startDate, requestParameters.endDate, requestParameters.gender, requestParameters.age, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerRemoveAdminUserFromFacilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerRemoveAdminUserFromFacility(requestParameters: DefaultApiDashboardControllerRemoveAdminUserFromFacilityRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.dashboardControllerRemoveAdminUserFromFacility(requestParameters.facilityId, requestParameters.adminUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerStatisticsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerStatistics(requestParameters: DefaultApiDashboardControllerStatisticsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Dashboard> {
            return localVarFp.dashboardControllerStatistics(requestParameters.superFacilityId, requestParameters.endDate, requestParameters.startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerTest(options?: AxiosRequestConfig): AxiosPromise<Array<OverviewData>> {
            return localVarFp.dashboardControllerTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerTestSocketUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerTestSocketUpdate(requestParameters: DefaultApiDashboardControllerTestSocketUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.dashboardControllerTestSocketUpdate(requestParameters.facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardControllerTriggerSlackReportForDateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerTriggerSlackReportForDate(requestParameters: DefaultApiDashboardControllerTriggerSlackReportForDateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.dashboardControllerTriggerSlackReportForDate(requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardSmsControllerCallbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSmsControllerCallback(requestParameters: DefaultApiDashboardSmsControllerCallbackRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.dashboardSmsControllerCallback(requestParameters.gatewayAPICallbackBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardSmsControllerGetBulkSmsForFacilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSmsControllerGetBulkSmsForFacility(requestParameters: DefaultApiDashboardSmsControllerGetBulkSmsForFacilityRequest, options?: AxiosRequestConfig): AxiosPromise<Array<BulkSmsWithShortUrls>> {
            return localVarFp.dashboardSmsControllerGetBulkSmsForFacility(requestParameters.facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardSmsControllerGetSmsForUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSmsControllerGetSmsForUser(requestParameters: DefaultApiDashboardSmsControllerGetSmsForUserRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Sms>> {
            return localVarFp.dashboardSmsControllerGetSmsForUser(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardSmsControllerSendBulkSMSRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSmsControllerSendBulkSMS(requestParameters: DefaultApiDashboardSmsControllerSendBulkSMSRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.dashboardSmsControllerSendBulkSMS(requestParameters.sendSmsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiDashboardSmsControllerSendSMSToUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSmsControllerSendSMSToUser(requestParameters: DefaultApiDashboardSmsControllerSendSMSToUserRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.dashboardSmsControllerSendSMSToUser(requestParameters.sendSmsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiEventsControllerGetEventsByAdminUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsControllerGetEventsByAdminUser(requestParameters: DefaultApiEventsControllerGetEventsByAdminUserRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<EventWithZoneAndSlot>> {
            return localVarFp.eventsControllerGetEventsByAdminUser(requestParameters.scope, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiEventsControllerGetEventsBySlotIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsControllerGetEventsBySlotId(requestParameters: DefaultApiEventsControllerGetEventsBySlotIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<EventWithZoneAndSlotAndPreviousAndNext>> {
            return localVarFp.eventsControllerGetEventsBySlotId(requestParameters.slotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiEventsControllerGetEventsByUserIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventsControllerGetEventsByUserId(requestParameters: DefaultApiEventsControllerGetEventsByUserIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<EventWithZoneAndSlot>> {
            return localVarFp.eventsControllerGetEventsByUserId(requestParameters.userId, requestParameters.scope, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFacilitiesControllerCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerCreate(requestParameters: DefaultApiFacilitiesControllerCreateRequest, options?: AxiosRequestConfig): AxiosPromise<Facility> {
            return localVarFp.facilitiesControllerCreate(requestParameters.createFacilityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetAllFacilities(options?: AxiosRequestConfig): AxiosPromise<Array<Facility>> {
            return localVarFp.facilitiesControllerGetAllFacilities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFacilitiesControllerGetEntranceSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetEntranceSummary(requestParameters: DefaultApiFacilitiesControllerGetEntranceSummaryRequest, options?: AxiosRequestConfig): AxiosPromise<EntranceSummary> {
            return localVarFp.facilitiesControllerGetEntranceSummary(requestParameters.id, requestParameters.since, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetFacilitiesByUser(options?: AxiosRequestConfig): AxiosPromise<Array<Facility>> {
            return localVarFp.facilitiesControllerGetFacilitiesByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetFacilitiesForPayments(options?: AxiosRequestConfig): AxiosPromise<Array<FacilityForPayments>> {
            return localVarFp.facilitiesControllerGetFacilitiesForPayments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFacilitiesControllerGetFacilityByNameRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetFacilityByName(requestParameters: DefaultApiFacilitiesControllerGetFacilityByNameRequest, options?: AxiosRequestConfig): AxiosPromise<Facility> {
            return localVarFp.facilitiesControllerGetFacilityByName(requestParameters.facilityName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFacilitiesControllerGetFacilityEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetFacilityEvents(requestParameters: DefaultApiFacilitiesControllerGetFacilityEventsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<FacilityWithAndZonesAndSlots>> {
            return localVarFp.facilitiesControllerGetFacilityEvents(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFacilitiesControllerGetFacilityHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerGetFacilityHistory(requestParameters: DefaultApiFacilitiesControllerGetFacilityHistoryRequest, options?: AxiosRequestConfig): AxiosPromise<Array<FacilityHistory>> {
            return localVarFp.facilitiesControllerGetFacilityHistory(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerTestUpdate(options?: AxiosRequestConfig): AxiosPromise<Facility> {
            return localVarFp.facilitiesControllerTestUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFacilitiesControllerUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilitiesControllerUpdate(requestParameters: DefaultApiFacilitiesControllerUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<Facility> {
            return localVarFp.facilitiesControllerUpdate(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFeatureFlagControllerIsEnabledRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureFlagControllerIsEnabled(requestParameters: DefaultApiFeatureFlagControllerIsEnabledRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.featureFlagControllerIsEnabled(requestParameters.feature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFeatureFlagControllerIsEnabledForFacilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureFlagControllerIsEnabledForFacility(requestParameters: DefaultApiFeatureFlagControllerIsEnabledForFacilityRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.featureFlagControllerIsEnabledForFacility(requestParameters.feature, requestParameters.facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFeatureFlagControllerIsEnabledForPaymentCodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureFlagControllerIsEnabledForPaymentCode(requestParameters: DefaultApiFeatureFlagControllerIsEnabledForPaymentCodeRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.featureFlagControllerIsEnabledForPaymentCode(requestParameters.feature, requestParameters.paymentCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerActivePaymentsForUserByAdminRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerActivePaymentsForUserByAdmin(requestParameters: DefaultApiPaymentsControllerActivePaymentsForUserByAdminRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Payment>> {
            return localVarFp.paymentsControllerActivePaymentsForUserByAdmin(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerConfirmPaymentIntentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerConfirmPaymentIntent(requestParameters: DefaultApiPaymentsControllerConfirmPaymentIntentRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentIntent> {
            return localVarFp.paymentsControllerConfirmPaymentIntent(requestParameters.paymentIntentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerConfirmSessionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerConfirmSession(requestParameters: DefaultApiPaymentsControllerConfirmSessionRequest, options?: AxiosRequestConfig): AxiosPromise<CheckoutSession> {
            return localVarFp.paymentsControllerConfirmSession(requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerFindAllByUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerFindAllByUser(requestParameters: DefaultApiPaymentsControllerFindAllByUserRequest, options?: AxiosRequestConfig): AxiosPromise<Array<PaymentWithFacilityAndReceiptAndRefundRequests>> {
            return localVarFp.paymentsControllerFindAllByUser(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerFindOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerFindOne(requestParameters: DefaultApiPaymentsControllerFindOneRequest, options?: AxiosRequestConfig): AxiosPromise<Payment> {
            return localVarFp.paymentsControllerFindOne(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerFindOneForUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerFindOneForUser(requestParameters: DefaultApiPaymentsControllerFindOneForUserRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentWithFacilityAndReceiptAndRefundRequests> {
            return localVarFp.paymentsControllerFindOneForUser(requestParameters.paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerFindPaymentsForUser(options?: AxiosRequestConfig): AxiosPromise<Array<PaymentWithFacility>> {
            return localVarFp.paymentsControllerFindPaymentsForUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerInitPaymentForFacilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerInitPaymentForFacility(requestParameters: DefaultApiPaymentsControllerInitPaymentForFacilityRequest, options?: AxiosRequestConfig): AxiosPromise<CheckoutSession> {
            return localVarFp.paymentsControllerInitPaymentForFacility(requestParameters.facilityId, requestParameters.initPaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerInitPaymentIntentForFacilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerInitPaymentIntentForFacility(requestParameters: DefaultApiPaymentsControllerInitPaymentIntentForFacilityRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentIntent> {
            return localVarFp.paymentsControllerInitPaymentIntentForFacility(requestParameters.facilityId, requestParameters.initPaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerInitSwishByAdmin(options?: AxiosRequestConfig): AxiosPromise<SwishPaymentRequestBase> {
            return localVarFp.paymentsControllerInitSwishByAdmin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerInitSwishForFacilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerInitSwishForFacility(requestParameters: DefaultApiPaymentsControllerInitSwishForFacilityRequest, options?: AxiosRequestConfig): AxiosPromise<SwishPaymentRequest> {
            return localVarFp.paymentsControllerInitSwishForFacility(requestParameters.facilityId, requestParameters.initPaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerIntentStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerIntentStatus(requestParameters: DefaultApiPaymentsControllerIntentStatusRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentIntent> {
            return localVarFp.paymentsControllerIntentStatus(requestParameters.paymentIntentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerSessionStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSessionStatus(requestParameters: DefaultApiPaymentsControllerSessionStatusRequest, options?: AxiosRequestConfig): AxiosPromise<CheckoutSession> {
            return localVarFp.paymentsControllerSessionStatus(requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerSwishCallbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSwishCallback(requestParameters: DefaultApiPaymentsControllerSwishCallbackRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.paymentsControllerSwishCallback(requestParameters.swishCallbackBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerSwishCallbackQrCodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSwishCallbackQrCode(requestParameters: DefaultApiPaymentsControllerSwishCallbackQrCodeRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.paymentsControllerSwishCallbackQrCode(requestParameters.swishCallbackBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerSwishStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSwishStatus(requestParameters: DefaultApiPaymentsControllerSwishStatusRequest, options?: AxiosRequestConfig): AxiosPromise<SwishStatus> {
            return localVarFp.paymentsControllerSwishStatus(requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerSwishStatusByAdminRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSwishStatusByAdmin(requestParameters: DefaultApiPaymentsControllerSwishStatusByAdminRequest, options?: AxiosRequestConfig): AxiosPromise<SwishStatus> {
            return localVarFp.paymentsControllerSwishStatusByAdmin(requestParameters.swishPaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPaymentsControllerTriggerPaymentForUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerTriggerPaymentForUser(requestParameters: DefaultApiPaymentsControllerTriggerPaymentForUserRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.paymentsControllerTriggerPaymentForUser(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiRedirectsControllerRedirectToOriginalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerRedirectToOriginal(requestParameters: DefaultApiRedirectsControllerRedirectToOriginalRequest, options?: AxiosRequestConfig): AxiosPromise<RedirectResponse> {
            return localVarFp.redirectsControllerRedirectToOriginal(requestParameters.code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiRefundRequestsControllerCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerCreate(requestParameters: DefaultApiRefundRequestsControllerCreateRequest, options?: AxiosRequestConfig): AxiosPromise<RefundRequest> {
            return localVarFp.refundRequestsControllerCreate(requestParameters.createRefundRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerFindAll(options?: AxiosRequestConfig): AxiosPromise<Array<RefundRequest>> {
            return localVarFp.refundRequestsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiRefundRequestsControllerFindAllByFacilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerFindAllByFacility(requestParameters: DefaultApiRefundRequestsControllerFindAllByFacilityRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RefundRequestWithPayment>> {
            return localVarFp.refundRequestsControllerFindAllByFacility(requestParameters.facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiRefundRequestsControllerFindAllByUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerFindAllByUser(requestParameters: DefaultApiRefundRequestsControllerFindAllByUserRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RefundRequestWithPayment>> {
            return localVarFp.refundRequestsControllerFindAllByUser(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiRefundRequestsControllerFindOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerFindOne(requestParameters: DefaultApiRefundRequestsControllerFindOneRequest, options?: AxiosRequestConfig): AxiosPromise<RefundRequest> {
            return localVarFp.refundRequestsControllerFindOne(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiRefundRequestsControllerRefundByAdminRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerRefundByAdmin(requestParameters: DefaultApiRefundRequestsControllerRefundByAdminRequest, options?: AxiosRequestConfig): AxiosPromise<RefundRequest> {
            return localVarFp.refundRequestsControllerRefundByAdmin(requestParameters.createRefundRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiRefundRequestsControllerSwishCallbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerSwishCallback(requestParameters: DefaultApiRefundRequestsControllerSwishCallbackRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.refundRequestsControllerSwishCallback(requestParameters.swishRefund, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiRefundRequestsControllerUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundRequestsControllerUpdate(requestParameters: DefaultApiRefundRequestsControllerUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<RefundRequest> {
            return localVarFp.refundRequestsControllerUpdate(requestParameters.id, requestParameters.updateRefundRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerAddAdditionalUserToSlotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerAddAdditionalUserToSlot(requestParameters: DefaultApiSlotsControllerAddAdditionalUserToSlotRequest, options?: AxiosRequestConfig): AxiosPromise<SlotWithZone> {
            return localVarFp.slotsControllerAddAdditionalUserToSlot(requestParameters.slotId, requestParameters.addAdditionalUserToSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerArchiveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerArchive(requestParameters: DefaultApiSlotsControllerArchiveRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.slotsControllerArchive(requestParameters.archiveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerArchivedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerArchived(requestParameters: DefaultApiSlotsControllerArchivedRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SlotWithZone>> {
            return localVarFp.slotsControllerArchived(requestParameters.facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerCheckInRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerCheckIn(requestParameters: DefaultApiSlotsControllerCheckInRequest, options?: AxiosRequestConfig): AxiosPromise<Slot> {
            return localVarFp.slotsControllerCheckIn(requestParameters.checkInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerCheckOutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerCheckOut(requestParameters: DefaultApiSlotsControllerCheckOutRequest, options?: AxiosRequestConfig): AxiosPromise<Slot> {
            return localVarFp.slotsControllerCheckOut(requestParameters.checkOutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerCheckOutByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerCheckOutById(requestParameters: DefaultApiSlotsControllerCheckOutByIdRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.slotsControllerCheckOutById(requestParameters.slotId, requestParameters.checkOutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerGetBySlotIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerGetBySlotId(requestParameters: DefaultApiSlotsControllerGetBySlotIdRequest, options?: AxiosRequestConfig): AxiosPromise<SlotWithZone> {
            return localVarFp.slotsControllerGetBySlotId(requestParameters.slotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerGetByUserIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerGetByUserId(requestParameters: DefaultApiSlotsControllerGetByUserIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SlotWithZone>> {
            return localVarFp.slotsControllerGetByUserId(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerGetByZoneIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerGetByZoneId(requestParameters: DefaultApiSlotsControllerGetByZoneIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Slot>> {
            return localVarFp.slotsControllerGetByZoneId(requestParameters.zoneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerMoveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerMove(requestParameters: DefaultApiSlotsControllerMoveRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.slotsControllerMove(requestParameters.moveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerReserveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerReserve(requestParameters: DefaultApiSlotsControllerReserveRequest, options?: AxiosRequestConfig): AxiosPromise<SlotWithZone> {
            return localVarFp.slotsControllerReserve(requestParameters.reserveSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerReserveSpecificSlotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerReserveSpecificSlot(requestParameters: DefaultApiSlotsControllerReserveSpecificSlotRequest, options?: AxiosRequestConfig): AxiosPromise<SlotWithZone> {
            return localVarFp.slotsControllerReserveSpecificSlot(requestParameters.slotId, requestParameters.reserveSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerRevertCheckoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerRevertCheckout(requestParameters: DefaultApiSlotsControllerRevertCheckoutRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.slotsControllerRevertCheckout(requestParameters.eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerSearchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerSearch(requestParameters: DefaultApiSlotsControllerSearchRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SlotWithZone>> {
            return localVarFp.slotsControllerSearch(requestParameters.facilityId, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerUnreserveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerUnreserve(requestParameters: DefaultApiSlotsControllerUnreserveRequest, options?: AxiosRequestConfig): AxiosPromise<SlotWithZone> {
            return localVarFp.slotsControllerUnreserve(requestParameters.unreserveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerUnreserveSpecificSlotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerUnreserveSpecificSlot(requestParameters: DefaultApiSlotsControllerUnreserveSpecificSlotRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.slotsControllerUnreserveSpecificSlot(requestParameters.slotId, requestParameters.unreserveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerUpdate(requestParameters: DefaultApiSlotsControllerUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<Slot> {
            return localVarFp.slotsControllerUpdate(requestParameters.id, requestParameters.updateSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSlotsControllerValidateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsControllerValidate(requestParameters: DefaultApiSlotsControllerValidateRequest, options?: AxiosRequestConfig): AxiosPromise<Slot> {
            return localVarFp.slotsControllerValidate(requestParameters.id, requestParameters.validateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiTicketsControllerConsumeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketsControllerConsume(requestParameters: DefaultApiTicketsControllerConsumeRequest, options?: AxiosRequestConfig): AxiosPromise<Ticket> {
            return localVarFp.ticketsControllerConsume(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiTicketsControllerResetConsumedAtRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketsControllerResetConsumedAt(requestParameters: DefaultApiTicketsControllerResetConsumedAtRequest, options?: AxiosRequestConfig): AxiosPromise<Ticket> {
            return localVarFp.ticketsControllerResetConsumedAt(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiTicketsControllerValidateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketsControllerValidate(requestParameters: DefaultApiTicketsControllerValidateRequest, options?: AxiosRequestConfig): AxiosPromise<Ticket> {
            return localVarFp.ticketsControllerValidate(requestParameters.id, requestParameters.validateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiUsersControllerConfirmAddPhoneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerConfirmAddPhone(requestParameters: DefaultApiUsersControllerConfirmAddPhoneRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersControllerConfirmAddPhone(requestParameters.id, requestParameters.phone, requestParameters.otp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiUsersControllerCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate(requestParameters: DefaultApiUsersControllerCreateRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersControllerCreate(requestParameters.createUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindMe(options?: AxiosRequestConfig): AxiosPromise<AuthUser> {
            return localVarFp.usersControllerFindMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiUsersControllerGetBySSNRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetBySSN(requestParameters: DefaultApiUsersControllerGetBySSNRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersControllerGetBySSN(requestParameters.ssn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetQrCodes(options?: AxiosRequestConfig): AxiosPromise<QrCodes> {
            return localVarFp.usersControllerGetQrCodes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiUsersControllerGetUserByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserById(requestParameters: DefaultApiUsersControllerGetUserByIdRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersControllerGetUserById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiUsersControllerInitAddPhoneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInitAddPhone(requestParameters: DefaultApiUsersControllerInitAddPhoneRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersControllerInitAddPhone(requestParameters.id, requestParameters.phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiUsersControllerRemoveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemove(requestParameters: DefaultApiUsersControllerRemoveRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.usersControllerRemove(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiUsersControllerSearchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSearch(requestParameters: DefaultApiUsersControllerSearchRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UserWithSlots>> {
            return localVarFp.usersControllerSearch(requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiUsersControllerSearchByBirthdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSearchByBirthdate(requestParameters: DefaultApiUsersControllerSearchByBirthdateRequest, options?: AxiosRequestConfig): AxiosPromise<Array<User>> {
            return localVarFp.usersControllerSearchByBirthdate(requestParameters.searchByBirthdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiUsersControllerUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate(requestParameters: DefaultApiUsersControllerUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersControllerUpdate(requestParameters.id, requestParameters.updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiZonesControllerCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerCreate(requestParameters: DefaultApiZonesControllerCreateRequest, options?: AxiosRequestConfig): AxiosPromise<Zone> {
            return localVarFp.zonesControllerCreate(requestParameters.createZoneDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiZonesControllerFindAllByFacilityIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerFindAllByFacilityId(requestParameters: DefaultApiZonesControllerFindAllByFacilityIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ZoneWithData>> {
            return localVarFp.zonesControllerFindAllByFacilityId(requestParameters.facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiZonesControllerFindBySlotIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerFindBySlotId(requestParameters: DefaultApiZonesControllerFindBySlotIdRequest, options?: AxiosRequestConfig): AxiosPromise<Zone> {
            return localVarFp.zonesControllerFindBySlotId(requestParameters.slotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiZonesControllerFindOneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerFindOne(requestParameters: DefaultApiZonesControllerFindOneRequest, options?: AxiosRequestConfig): AxiosPromise<Zone> {
            return localVarFp.zonesControllerFindOne(requestParameters.zoneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiZonesControllerFindOneWithSlotsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerFindOneWithSlots(requestParameters: DefaultApiZonesControllerFindOneWithSlotsRequest, options?: AxiosRequestConfig): AxiosPromise<ZoneWithSlots> {
            return localVarFp.zonesControllerFindOneWithSlots(requestParameters.zoneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiZonesControllerRemoveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerRemove(requestParameters: DefaultApiZonesControllerRemoveRequest, options?: AxiosRequestConfig): AxiosPromise<Zone> {
            return localVarFp.zonesControllerRemove(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiZonesControllerUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesControllerUpdate(requestParameters: DefaultApiZonesControllerUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<Zone> {
            return localVarFp.zonesControllerUpdate(requestParameters.id, requestParameters.updateZoneDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminUsersControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminUsersControllerCreateRequest
 */
export interface DefaultApiAdminUsersControllerCreateRequest {
    /**
     * 
     * @type {CreateAdminUserDto}
     * @memberof DefaultApiAdminUsersControllerCreate
     */
    readonly createAdminUserDto: CreateAdminUserDto
}

/**
 * Request parameters for adminUsersControllerCreateWardrobeAdmin operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminUsersControllerCreateWardrobeAdminRequest
 */
export interface DefaultApiAdminUsersControllerCreateWardrobeAdminRequest {
    /**
     * 
     * @type {CreateWardrobeAdminDto}
     * @memberof DefaultApiAdminUsersControllerCreateWardrobeAdmin
     */
    readonly createWardrobeAdminDto: CreateWardrobeAdminDto
}

/**
 * Request parameters for adminUsersControllerFindAll operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminUsersControllerFindAllRequest
 */
export interface DefaultApiAdminUsersControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAdminUsersControllerFindAll
     */
    readonly facilityId?: string
}

/**
 * Request parameters for adminUsersControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminUsersControllerFindOneRequest
 */
export interface DefaultApiAdminUsersControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAdminUsersControllerFindOne
     */
    readonly id: string
}

/**
 * Request parameters for adminUsersControllerRemove operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminUsersControllerRemoveRequest
 */
export interface DefaultApiAdminUsersControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAdminUsersControllerRemove
     */
    readonly id: string
}

/**
 * Request parameters for adminUsersControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminUsersControllerUpdateRequest
 */
export interface DefaultApiAdminUsersControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAdminUsersControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateAdminUserDto}
     * @memberof DefaultApiAdminUsersControllerUpdate
     */
    readonly updateAdminUserDto: UpdateAdminUserDto
}

/**
 * Request parameters for adminUsersControllerUpdateFacility operation in DefaultApi.
 * @export
 * @interface DefaultApiAdminUsersControllerUpdateFacilityRequest
 */
export interface DefaultApiAdminUsersControllerUpdateFacilityRequest {
    /**
     * 
     * @type {UpdateAdminUserFacilityDto}
     * @memberof DefaultApiAdminUsersControllerUpdateFacility
     */
    readonly updateAdminUserFacilityDto: UpdateAdminUserFacilityDto
}

/**
 * Request parameters for authControllerGetSession operation in DefaultApi.
 * @export
 * @interface DefaultApiAuthControllerGetSessionRequest
 */
export interface DefaultApiAuthControllerGetSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAuthControllerGetSession
     */
    readonly id: string
}

/**
 * Request parameters for authControllerInitAdminPhoneSignIn operation in DefaultApi.
 * @export
 * @interface DefaultApiAuthControllerInitAdminPhoneSignInRequest
 */
export interface DefaultApiAuthControllerInitAdminPhoneSignInRequest {
    /**
     * 
     * @type {InitAdminPhoneDto}
     * @memberof DefaultApiAuthControllerInitAdminPhoneSignIn
     */
    readonly initAdminPhoneDto: InitAdminPhoneDto
}

/**
 * Request parameters for authControllerInitUserPhoneSignIn operation in DefaultApi.
 * @export
 * @interface DefaultApiAuthControllerInitUserPhoneSignInRequest
 */
export interface DefaultApiAuthControllerInitUserPhoneSignInRequest {
    /**
     * 
     * @type {InitUserPhoneSignInDto}
     * @memberof DefaultApiAuthControllerInitUserPhoneSignIn
     */
    readonly initUserPhoneSignInDto: InitUserPhoneSignInDto
}

/**
 * Request parameters for authControllerInitUserPhoneSignInByAdmin operation in DefaultApi.
 * @export
 * @interface DefaultApiAuthControllerInitUserPhoneSignInByAdminRequest
 */
export interface DefaultApiAuthControllerInitUserPhoneSignInByAdminRequest {
    /**
     * 
     * @type {InitUserPhoneSignInDto}
     * @memberof DefaultApiAuthControllerInitUserPhoneSignInByAdmin
     */
    readonly initUserPhoneSignInDto: InitUserPhoneSignInDto
}

/**
 * Request parameters for authControllerSignInAdminByPhone operation in DefaultApi.
 * @export
 * @interface DefaultApiAuthControllerSignInAdminByPhoneRequest
 */
export interface DefaultApiAuthControllerSignInAdminByPhoneRequest {
    /**
     * 
     * @type {SignInAdminPhoneDto}
     * @memberof DefaultApiAuthControllerSignInAdminByPhone
     */
    readonly signInAdminPhoneDto: SignInAdminPhoneDto
}

/**
 * Request parameters for companiesControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiCompaniesControllerCreateRequest
 */
export interface DefaultApiCompaniesControllerCreateRequest {
    /**
     * 
     * @type {CreateCompanyDto}
     * @memberof DefaultApiCompaniesControllerCreate
     */
    readonly createCompanyDto: CreateCompanyDto
}

/**
 * Request parameters for companiesControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiCompaniesControllerFindOneRequest
 */
export interface DefaultApiCompaniesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCompaniesControllerFindOne
     */
    readonly id: string
}

/**
 * Request parameters for companiesControllerRemove operation in DefaultApi.
 * @export
 * @interface DefaultApiCompaniesControllerRemoveRequest
 */
export interface DefaultApiCompaniesControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCompaniesControllerRemove
     */
    readonly id: string
}

/**
 * Request parameters for companiesControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiCompaniesControllerUpdateRequest
 */
export interface DefaultApiCompaniesControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCompaniesControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateCompanyDto}
     * @memberof DefaultApiCompaniesControllerUpdate
     */
    readonly updateCompanyDto: UpdateCompanyDto
}

/**
 * Request parameters for dashboardControllerCreateTransactionsReport operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerCreateTransactionsReportRequest
 */
export interface DefaultApiDashboardControllerCreateTransactionsReportRequest {
    /**
     * 
     * @type {CreateReportDto}
     * @memberof DefaultApiDashboardControllerCreateTransactionsReport
     */
    readonly createReportDto: CreateReportDto
}

/**
 * Request parameters for dashboardControllerCreateZReport operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerCreateZReportRequest
 */
export interface DefaultApiDashboardControllerCreateZReportRequest {
    /**
     * 
     * @type {CreateReportDto}
     * @memberof DefaultApiDashboardControllerCreateZReport
     */
    readonly createReportDto: CreateReportDto
}

/**
 * Request parameters for dashboardControllerExportUsersForFacilityToCsv operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerExportUsersForFacilityToCsvRequest
 */
export interface DefaultApiDashboardControllerExportUsersForFacilityToCsvRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerExportUsersForFacilityToCsv
     */
    readonly facilityId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerExportUsersForFacilityToCsv
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerExportUsersForFacilityToCsv
     */
    readonly endDate: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerExportUsersForFacilityToCsv
     */
    readonly gender?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerExportUsersForFacilityToCsv
     */
    readonly age?: string
}

/**
 * Request parameters for dashboardControllerGetFacilityEvents operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerGetFacilityEventsRequest
 */
export interface DefaultApiDashboardControllerGetFacilityEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerGetFacilityEvents
     */
    readonly facilityId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerGetFacilityEvents
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerGetFacilityEvents
     */
    readonly endDate: string
}

/**
 * Request parameters for dashboardControllerGetOverview operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerGetOverviewRequest
 */
export interface DefaultApiDashboardControllerGetOverviewRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerGetOverview
     */
    readonly endDate: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerGetOverview
     */
    readonly startDate: string
}

/**
 * Request parameters for dashboardControllerGetReportData operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerGetReportDataRequest
 */
export interface DefaultApiDashboardControllerGetReportDataRequest {
    /**
     * 
     * @type {CreateReportDto}
     * @memberof DefaultApiDashboardControllerGetReportData
     */
    readonly createReportDto: CreateReportDto
}

/**
 * Request parameters for dashboardControllerGetUsersForFacility operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerGetUsersForFacilityRequest
 */
export interface DefaultApiDashboardControllerGetUsersForFacilityRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerGetUsersForFacility
     */
    readonly facilityId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerGetUsersForFacility
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerGetUsersForFacility
     */
    readonly endDate: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerGetUsersForFacility
     */
    readonly gender?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerGetUsersForFacility
     */
    readonly age?: string
}

/**
 * Request parameters for dashboardControllerRemoveAdminUserFromFacility operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerRemoveAdminUserFromFacilityRequest
 */
export interface DefaultApiDashboardControllerRemoveAdminUserFromFacilityRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerRemoveAdminUserFromFacility
     */
    readonly facilityId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerRemoveAdminUserFromFacility
     */
    readonly adminUserId: string
}

/**
 * Request parameters for dashboardControllerStatistics operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerStatisticsRequest
 */
export interface DefaultApiDashboardControllerStatisticsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerStatistics
     */
    readonly superFacilityId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerStatistics
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerStatistics
     */
    readonly startDate?: string
}

/**
 * Request parameters for dashboardControllerTestSocketUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerTestSocketUpdateRequest
 */
export interface DefaultApiDashboardControllerTestSocketUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerTestSocketUpdate
     */
    readonly facilityId: string
}

/**
 * Request parameters for dashboardControllerTriggerSlackReportForDate operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardControllerTriggerSlackReportForDateRequest
 */
export interface DefaultApiDashboardControllerTriggerSlackReportForDateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardControllerTriggerSlackReportForDate
     */
    readonly date?: string
}

/**
 * Request parameters for dashboardSmsControllerCallback operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardSmsControllerCallbackRequest
 */
export interface DefaultApiDashboardSmsControllerCallbackRequest {
    /**
     * 
     * @type {GatewayAPICallbackBody}
     * @memberof DefaultApiDashboardSmsControllerCallback
     */
    readonly gatewayAPICallbackBody: GatewayAPICallbackBody
}

/**
 * Request parameters for dashboardSmsControllerGetBulkSmsForFacility operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardSmsControllerGetBulkSmsForFacilityRequest
 */
export interface DefaultApiDashboardSmsControllerGetBulkSmsForFacilityRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardSmsControllerGetBulkSmsForFacility
     */
    readonly facilityId: string
}

/**
 * Request parameters for dashboardSmsControllerGetSmsForUser operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardSmsControllerGetSmsForUserRequest
 */
export interface DefaultApiDashboardSmsControllerGetSmsForUserRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiDashboardSmsControllerGetSmsForUser
     */
    readonly userId: string
}

/**
 * Request parameters for dashboardSmsControllerSendBulkSMS operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardSmsControllerSendBulkSMSRequest
 */
export interface DefaultApiDashboardSmsControllerSendBulkSMSRequest {
    /**
     * 
     * @type {SendSmsDto}
     * @memberof DefaultApiDashboardSmsControllerSendBulkSMS
     */
    readonly sendSmsDto: SendSmsDto
}

/**
 * Request parameters for dashboardSmsControllerSendSMSToUser operation in DefaultApi.
 * @export
 * @interface DefaultApiDashboardSmsControllerSendSMSToUserRequest
 */
export interface DefaultApiDashboardSmsControllerSendSMSToUserRequest {
    /**
     * 
     * @type {SendSmsDto}
     * @memberof DefaultApiDashboardSmsControllerSendSMSToUser
     */
    readonly sendSmsDto: SendSmsDto
}

/**
 * Request parameters for eventsControllerGetEventsByAdminUser operation in DefaultApi.
 * @export
 * @interface DefaultApiEventsControllerGetEventsByAdminUserRequest
 */
export interface DefaultApiEventsControllerGetEventsByAdminUserRequest {
    /**
     * 
     * @type {'ALL' | 'ME'}
     * @memberof DefaultApiEventsControllerGetEventsByAdminUser
     */
    readonly scope?: 'ALL' | 'ME'

    /**
     * 
     * @type {number}
     * @memberof DefaultApiEventsControllerGetEventsByAdminUser
     */
    readonly limit?: number
}

/**
 * Request parameters for eventsControllerGetEventsBySlotId operation in DefaultApi.
 * @export
 * @interface DefaultApiEventsControllerGetEventsBySlotIdRequest
 */
export interface DefaultApiEventsControllerGetEventsBySlotIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEventsControllerGetEventsBySlotId
     */
    readonly slotId: string
}

/**
 * Request parameters for eventsControllerGetEventsByUserId operation in DefaultApi.
 * @export
 * @interface DefaultApiEventsControllerGetEventsByUserIdRequest
 */
export interface DefaultApiEventsControllerGetEventsByUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEventsControllerGetEventsByUserId
     */
    readonly userId: string

    /**
     * 
     * @type {'ALL' | 'SELECTED_FACILITY'}
     * @memberof DefaultApiEventsControllerGetEventsByUserId
     */
    readonly scope?: 'ALL' | 'SELECTED_FACILITY'
}

/**
 * Request parameters for facilitiesControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiFacilitiesControllerCreateRequest
 */
export interface DefaultApiFacilitiesControllerCreateRequest {
    /**
     * 
     * @type {CreateFacilityDto}
     * @memberof DefaultApiFacilitiesControllerCreate
     */
    readonly createFacilityDto: CreateFacilityDto
}

/**
 * Request parameters for facilitiesControllerGetEntranceSummary operation in DefaultApi.
 * @export
 * @interface DefaultApiFacilitiesControllerGetEntranceSummaryRequest
 */
export interface DefaultApiFacilitiesControllerGetEntranceSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFacilitiesControllerGetEntranceSummary
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiFacilitiesControllerGetEntranceSummary
     */
    readonly since: string
}

/**
 * Request parameters for facilitiesControllerGetFacilityByName operation in DefaultApi.
 * @export
 * @interface DefaultApiFacilitiesControllerGetFacilityByNameRequest
 */
export interface DefaultApiFacilitiesControllerGetFacilityByNameRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFacilitiesControllerGetFacilityByName
     */
    readonly facilityName: string
}

/**
 * Request parameters for facilitiesControllerGetFacilityEvents operation in DefaultApi.
 * @export
 * @interface DefaultApiFacilitiesControllerGetFacilityEventsRequest
 */
export interface DefaultApiFacilitiesControllerGetFacilityEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFacilitiesControllerGetFacilityEvents
     */
    readonly id: string
}

/**
 * Request parameters for facilitiesControllerGetFacilityHistory operation in DefaultApi.
 * @export
 * @interface DefaultApiFacilitiesControllerGetFacilityHistoryRequest
 */
export interface DefaultApiFacilitiesControllerGetFacilityHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFacilitiesControllerGetFacilityHistory
     */
    readonly id: string
}

/**
 * Request parameters for facilitiesControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiFacilitiesControllerUpdateRequest
 */
export interface DefaultApiFacilitiesControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFacilitiesControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {object}
     * @memberof DefaultApiFacilitiesControllerUpdate
     */
    readonly body: object
}

/**
 * Request parameters for featureFlagControllerIsEnabled operation in DefaultApi.
 * @export
 * @interface DefaultApiFeatureFlagControllerIsEnabledRequest
 */
export interface DefaultApiFeatureFlagControllerIsEnabledRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFeatureFlagControllerIsEnabled
     */
    readonly feature: string
}

/**
 * Request parameters for featureFlagControllerIsEnabledForFacility operation in DefaultApi.
 * @export
 * @interface DefaultApiFeatureFlagControllerIsEnabledForFacilityRequest
 */
export interface DefaultApiFeatureFlagControllerIsEnabledForFacilityRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFeatureFlagControllerIsEnabledForFacility
     */
    readonly feature: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiFeatureFlagControllerIsEnabledForFacility
     */
    readonly facilityId: string
}

/**
 * Request parameters for featureFlagControllerIsEnabledForPaymentCode operation in DefaultApi.
 * @export
 * @interface DefaultApiFeatureFlagControllerIsEnabledForPaymentCodeRequest
 */
export interface DefaultApiFeatureFlagControllerIsEnabledForPaymentCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFeatureFlagControllerIsEnabledForPaymentCode
     */
    readonly feature: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiFeatureFlagControllerIsEnabledForPaymentCode
     */
    readonly paymentCode: string
}

/**
 * Request parameters for paymentsControllerActivePaymentsForUserByAdmin operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerActivePaymentsForUserByAdminRequest
 */
export interface DefaultApiPaymentsControllerActivePaymentsForUserByAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerActivePaymentsForUserByAdmin
     */
    readonly userId: string
}

/**
 * Request parameters for paymentsControllerConfirmPaymentIntent operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerConfirmPaymentIntentRequest
 */
export interface DefaultApiPaymentsControllerConfirmPaymentIntentRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerConfirmPaymentIntent
     */
    readonly paymentIntentId: string
}

/**
 * Request parameters for paymentsControllerConfirmSession operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerConfirmSessionRequest
 */
export interface DefaultApiPaymentsControllerConfirmSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerConfirmSession
     */
    readonly sessionId: string
}

/**
 * Request parameters for paymentsControllerFindAllByUser operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerFindAllByUserRequest
 */
export interface DefaultApiPaymentsControllerFindAllByUserRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerFindAllByUser
     */
    readonly userId: string
}

/**
 * Request parameters for paymentsControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerFindOneRequest
 */
export interface DefaultApiPaymentsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerFindOne
     */
    readonly id: string
}

/**
 * Request parameters for paymentsControllerFindOneForUser operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerFindOneForUserRequest
 */
export interface DefaultApiPaymentsControllerFindOneForUserRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerFindOneForUser
     */
    readonly paymentId: string
}

/**
 * Request parameters for paymentsControllerInitPaymentForFacility operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerInitPaymentForFacilityRequest
 */
export interface DefaultApiPaymentsControllerInitPaymentForFacilityRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerInitPaymentForFacility
     */
    readonly facilityId: string

    /**
     * 
     * @type {InitPaymentDto}
     * @memberof DefaultApiPaymentsControllerInitPaymentForFacility
     */
    readonly initPaymentDto: InitPaymentDto
}

/**
 * Request parameters for paymentsControllerInitPaymentIntentForFacility operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerInitPaymentIntentForFacilityRequest
 */
export interface DefaultApiPaymentsControllerInitPaymentIntentForFacilityRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerInitPaymentIntentForFacility
     */
    readonly facilityId: string

    /**
     * 
     * @type {InitPaymentDto}
     * @memberof DefaultApiPaymentsControllerInitPaymentIntentForFacility
     */
    readonly initPaymentDto: InitPaymentDto
}

/**
 * Request parameters for paymentsControllerInitSwishForFacility operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerInitSwishForFacilityRequest
 */
export interface DefaultApiPaymentsControllerInitSwishForFacilityRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerInitSwishForFacility
     */
    readonly facilityId: string

    /**
     * 
     * @type {InitPaymentDto}
     * @memberof DefaultApiPaymentsControllerInitSwishForFacility
     */
    readonly initPaymentDto: InitPaymentDto
}

/**
 * Request parameters for paymentsControllerIntentStatus operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerIntentStatusRequest
 */
export interface DefaultApiPaymentsControllerIntentStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerIntentStatus
     */
    readonly paymentIntentId: string
}

/**
 * Request parameters for paymentsControllerSessionStatus operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerSessionStatusRequest
 */
export interface DefaultApiPaymentsControllerSessionStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerSessionStatus
     */
    readonly sessionId: string
}

/**
 * Request parameters for paymentsControllerSwishCallback operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerSwishCallbackRequest
 */
export interface DefaultApiPaymentsControllerSwishCallbackRequest {
    /**
     * 
     * @type {SwishCallbackBody}
     * @memberof DefaultApiPaymentsControllerSwishCallback
     */
    readonly swishCallbackBody: SwishCallbackBody
}

/**
 * Request parameters for paymentsControllerSwishCallbackQrCode operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerSwishCallbackQrCodeRequest
 */
export interface DefaultApiPaymentsControllerSwishCallbackQrCodeRequest {
    /**
     * 
     * @type {SwishCallbackBody}
     * @memberof DefaultApiPaymentsControllerSwishCallbackQrCode
     */
    readonly swishCallbackBody: SwishCallbackBody
}

/**
 * Request parameters for paymentsControllerSwishStatus operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerSwishStatusRequest
 */
export interface DefaultApiPaymentsControllerSwishStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerSwishStatus
     */
    readonly token: string
}

/**
 * Request parameters for paymentsControllerSwishStatusByAdmin operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerSwishStatusByAdminRequest
 */
export interface DefaultApiPaymentsControllerSwishStatusByAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerSwishStatusByAdmin
     */
    readonly swishPaymentId: string
}

/**
 * Request parameters for paymentsControllerTriggerPaymentForUser operation in DefaultApi.
 * @export
 * @interface DefaultApiPaymentsControllerTriggerPaymentForUserRequest
 */
export interface DefaultApiPaymentsControllerTriggerPaymentForUserRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPaymentsControllerTriggerPaymentForUser
     */
    readonly userId: string
}

/**
 * Request parameters for redirectsControllerRedirectToOriginal operation in DefaultApi.
 * @export
 * @interface DefaultApiRedirectsControllerRedirectToOriginalRequest
 */
export interface DefaultApiRedirectsControllerRedirectToOriginalRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRedirectsControllerRedirectToOriginal
     */
    readonly code: string
}

/**
 * Request parameters for refundRequestsControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiRefundRequestsControllerCreateRequest
 */
export interface DefaultApiRefundRequestsControllerCreateRequest {
    /**
     * 
     * @type {CreateRefundRequestDto}
     * @memberof DefaultApiRefundRequestsControllerCreate
     */
    readonly createRefundRequestDto: CreateRefundRequestDto
}

/**
 * Request parameters for refundRequestsControllerFindAllByFacility operation in DefaultApi.
 * @export
 * @interface DefaultApiRefundRequestsControllerFindAllByFacilityRequest
 */
export interface DefaultApiRefundRequestsControllerFindAllByFacilityRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRefundRequestsControllerFindAllByFacility
     */
    readonly facilityId: string
}

/**
 * Request parameters for refundRequestsControllerFindAllByUser operation in DefaultApi.
 * @export
 * @interface DefaultApiRefundRequestsControllerFindAllByUserRequest
 */
export interface DefaultApiRefundRequestsControllerFindAllByUserRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRefundRequestsControllerFindAllByUser
     */
    readonly userId: string
}

/**
 * Request parameters for refundRequestsControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiRefundRequestsControllerFindOneRequest
 */
export interface DefaultApiRefundRequestsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRefundRequestsControllerFindOne
     */
    readonly id: string
}

/**
 * Request parameters for refundRequestsControllerRefundByAdmin operation in DefaultApi.
 * @export
 * @interface DefaultApiRefundRequestsControllerRefundByAdminRequest
 */
export interface DefaultApiRefundRequestsControllerRefundByAdminRequest {
    /**
     * 
     * @type {CreateRefundRequestDto}
     * @memberof DefaultApiRefundRequestsControllerRefundByAdmin
     */
    readonly createRefundRequestDto: CreateRefundRequestDto
}

/**
 * Request parameters for refundRequestsControllerSwishCallback operation in DefaultApi.
 * @export
 * @interface DefaultApiRefundRequestsControllerSwishCallbackRequest
 */
export interface DefaultApiRefundRequestsControllerSwishCallbackRequest {
    /**
     * 
     * @type {SwishRefund}
     * @memberof DefaultApiRefundRequestsControllerSwishCallback
     */
    readonly swishRefund: SwishRefund
}

/**
 * Request parameters for refundRequestsControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiRefundRequestsControllerUpdateRequest
 */
export interface DefaultApiRefundRequestsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRefundRequestsControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateRefundRequestDto}
     * @memberof DefaultApiRefundRequestsControllerUpdate
     */
    readonly updateRefundRequestDto: UpdateRefundRequestDto
}

/**
 * Request parameters for slotsControllerAddAdditionalUserToSlot operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerAddAdditionalUserToSlotRequest
 */
export interface DefaultApiSlotsControllerAddAdditionalUserToSlotRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerAddAdditionalUserToSlot
     */
    readonly slotId: string

    /**
     * 
     * @type {AddAdditionalUserToSlotDto}
     * @memberof DefaultApiSlotsControllerAddAdditionalUserToSlot
     */
    readonly addAdditionalUserToSlotDto: AddAdditionalUserToSlotDto
}

/**
 * Request parameters for slotsControllerArchive operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerArchiveRequest
 */
export interface DefaultApiSlotsControllerArchiveRequest {
    /**
     * 
     * @type {ArchiveDto}
     * @memberof DefaultApiSlotsControllerArchive
     */
    readonly archiveDto: ArchiveDto
}

/**
 * Request parameters for slotsControllerArchived operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerArchivedRequest
 */
export interface DefaultApiSlotsControllerArchivedRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerArchived
     */
    readonly facilityId: string
}

/**
 * Request parameters for slotsControllerCheckIn operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerCheckInRequest
 */
export interface DefaultApiSlotsControllerCheckInRequest {
    /**
     * 
     * @type {CheckInDto}
     * @memberof DefaultApiSlotsControllerCheckIn
     */
    readonly checkInDto: CheckInDto
}

/**
 * Request parameters for slotsControllerCheckOut operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerCheckOutRequest
 */
export interface DefaultApiSlotsControllerCheckOutRequest {
    /**
     * 
     * @type {CheckOutDto}
     * @memberof DefaultApiSlotsControllerCheckOut
     */
    readonly checkOutDto: CheckOutDto
}

/**
 * Request parameters for slotsControllerCheckOutById operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerCheckOutByIdRequest
 */
export interface DefaultApiSlotsControllerCheckOutByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerCheckOutById
     */
    readonly slotId: string

    /**
     * 
     * @type {CheckOutDto}
     * @memberof DefaultApiSlotsControllerCheckOutById
     */
    readonly checkOutDto: CheckOutDto
}

/**
 * Request parameters for slotsControllerGetBySlotId operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerGetBySlotIdRequest
 */
export interface DefaultApiSlotsControllerGetBySlotIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerGetBySlotId
     */
    readonly slotId: string
}

/**
 * Request parameters for slotsControllerGetByUserId operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerGetByUserIdRequest
 */
export interface DefaultApiSlotsControllerGetByUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerGetByUserId
     */
    readonly userId: string
}

/**
 * Request parameters for slotsControllerGetByZoneId operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerGetByZoneIdRequest
 */
export interface DefaultApiSlotsControllerGetByZoneIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerGetByZoneId
     */
    readonly zoneId: string
}

/**
 * Request parameters for slotsControllerMove operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerMoveRequest
 */
export interface DefaultApiSlotsControllerMoveRequest {
    /**
     * 
     * @type {MoveDto}
     * @memberof DefaultApiSlotsControllerMove
     */
    readonly moveDto: MoveDto
}

/**
 * Request parameters for slotsControllerReserve operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerReserveRequest
 */
export interface DefaultApiSlotsControllerReserveRequest {
    /**
     * 
     * @type {ReserveSlotDto}
     * @memberof DefaultApiSlotsControllerReserve
     */
    readonly reserveSlotDto: ReserveSlotDto
}

/**
 * Request parameters for slotsControllerReserveSpecificSlot operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerReserveSpecificSlotRequest
 */
export interface DefaultApiSlotsControllerReserveSpecificSlotRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerReserveSpecificSlot
     */
    readonly slotId: string

    /**
     * 
     * @type {ReserveSlotDto}
     * @memberof DefaultApiSlotsControllerReserveSpecificSlot
     */
    readonly reserveSlotDto: ReserveSlotDto
}

/**
 * Request parameters for slotsControllerRevertCheckout operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerRevertCheckoutRequest
 */
export interface DefaultApiSlotsControllerRevertCheckoutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerRevertCheckout
     */
    readonly eventId: string
}

/**
 * Request parameters for slotsControllerSearch operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerSearchRequest
 */
export interface DefaultApiSlotsControllerSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerSearch
     */
    readonly facilityId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerSearch
     */
    readonly query: string
}

/**
 * Request parameters for slotsControllerUnreserve operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerUnreserveRequest
 */
export interface DefaultApiSlotsControllerUnreserveRequest {
    /**
     * 
     * @type {UnreserveDto}
     * @memberof DefaultApiSlotsControllerUnreserve
     */
    readonly unreserveDto: UnreserveDto
}

/**
 * Request parameters for slotsControllerUnreserveSpecificSlot operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerUnreserveSpecificSlotRequest
 */
export interface DefaultApiSlotsControllerUnreserveSpecificSlotRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerUnreserveSpecificSlot
     */
    readonly slotId: string

    /**
     * 
     * @type {UnreserveDto}
     * @memberof DefaultApiSlotsControllerUnreserveSpecificSlot
     */
    readonly unreserveDto: UnreserveDto
}

/**
 * Request parameters for slotsControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerUpdateRequest
 */
export interface DefaultApiSlotsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSlotDto}
     * @memberof DefaultApiSlotsControllerUpdate
     */
    readonly updateSlotDto: UpdateSlotDto
}

/**
 * Request parameters for slotsControllerValidate operation in DefaultApi.
 * @export
 * @interface DefaultApiSlotsControllerValidateRequest
 */
export interface DefaultApiSlotsControllerValidateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSlotsControllerValidate
     */
    readonly id: string

    /**
     * 
     * @type {ValidateDto}
     * @memberof DefaultApiSlotsControllerValidate
     */
    readonly validateDto: ValidateDto
}

/**
 * Request parameters for ticketsControllerConsume operation in DefaultApi.
 * @export
 * @interface DefaultApiTicketsControllerConsumeRequest
 */
export interface DefaultApiTicketsControllerConsumeRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiTicketsControllerConsume
     */
    readonly id: string
}

/**
 * Request parameters for ticketsControllerResetConsumedAt operation in DefaultApi.
 * @export
 * @interface DefaultApiTicketsControllerResetConsumedAtRequest
 */
export interface DefaultApiTicketsControllerResetConsumedAtRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiTicketsControllerResetConsumedAt
     */
    readonly id: string
}

/**
 * Request parameters for ticketsControllerValidate operation in DefaultApi.
 * @export
 * @interface DefaultApiTicketsControllerValidateRequest
 */
export interface DefaultApiTicketsControllerValidateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiTicketsControllerValidate
     */
    readonly id: string

    /**
     * 
     * @type {ValidateDto}
     * @memberof DefaultApiTicketsControllerValidate
     */
    readonly validateDto: ValidateDto
}

/**
 * Request parameters for usersControllerConfirmAddPhone operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerConfirmAddPhoneRequest
 */
export interface DefaultApiUsersControllerConfirmAddPhoneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersControllerConfirmAddPhone
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersControllerConfirmAddPhone
     */
    readonly phone: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersControllerConfirmAddPhone
     */
    readonly otp: string
}

/**
 * Request parameters for usersControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerCreateRequest
 */
export interface DefaultApiUsersControllerCreateRequest {
    /**
     * 
     * @type {CreateUserDto}
     * @memberof DefaultApiUsersControllerCreate
     */
    readonly createUserDto: CreateUserDto
}

/**
 * Request parameters for usersControllerGetBySSN operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerGetBySSNRequest
 */
export interface DefaultApiUsersControllerGetBySSNRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersControllerGetBySSN
     */
    readonly ssn: string
}

/**
 * Request parameters for usersControllerGetUserById operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerGetUserByIdRequest
 */
export interface DefaultApiUsersControllerGetUserByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersControllerGetUserById
     */
    readonly id: string
}

/**
 * Request parameters for usersControllerInitAddPhone operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerInitAddPhoneRequest
 */
export interface DefaultApiUsersControllerInitAddPhoneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersControllerInitAddPhone
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersControllerInitAddPhone
     */
    readonly phone: string
}

/**
 * Request parameters for usersControllerRemove operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerRemoveRequest
 */
export interface DefaultApiUsersControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersControllerRemove
     */
    readonly id: string
}

/**
 * Request parameters for usersControllerSearch operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerSearchRequest
 */
export interface DefaultApiUsersControllerSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersControllerSearch
     */
    readonly query: string
}

/**
 * Request parameters for usersControllerSearchByBirthdate operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerSearchByBirthdateRequest
 */
export interface DefaultApiUsersControllerSearchByBirthdateRequest {
    /**
     * 
     * @type {SearchByBirthdateDto}
     * @memberof DefaultApiUsersControllerSearchByBirthdate
     */
    readonly searchByBirthdateDto: SearchByBirthdateDto
}

/**
 * Request parameters for usersControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerUpdateRequest
 */
export interface DefaultApiUsersControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiUsersControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateUserDto}
     * @memberof DefaultApiUsersControllerUpdate
     */
    readonly updateUserDto: UpdateUserDto
}

/**
 * Request parameters for zonesControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiZonesControllerCreateRequest
 */
export interface DefaultApiZonesControllerCreateRequest {
    /**
     * 
     * @type {CreateZoneDto}
     * @memberof DefaultApiZonesControllerCreate
     */
    readonly createZoneDto: CreateZoneDto
}

/**
 * Request parameters for zonesControllerFindAllByFacilityId operation in DefaultApi.
 * @export
 * @interface DefaultApiZonesControllerFindAllByFacilityIdRequest
 */
export interface DefaultApiZonesControllerFindAllByFacilityIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiZonesControllerFindAllByFacilityId
     */
    readonly facilityId: string
}

/**
 * Request parameters for zonesControllerFindBySlotId operation in DefaultApi.
 * @export
 * @interface DefaultApiZonesControllerFindBySlotIdRequest
 */
export interface DefaultApiZonesControllerFindBySlotIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiZonesControllerFindBySlotId
     */
    readonly slotId: string
}

/**
 * Request parameters for zonesControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiZonesControllerFindOneRequest
 */
export interface DefaultApiZonesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiZonesControllerFindOne
     */
    readonly zoneId: string
}

/**
 * Request parameters for zonesControllerFindOneWithSlots operation in DefaultApi.
 * @export
 * @interface DefaultApiZonesControllerFindOneWithSlotsRequest
 */
export interface DefaultApiZonesControllerFindOneWithSlotsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiZonesControllerFindOneWithSlots
     */
    readonly zoneId: string
}

/**
 * Request parameters for zonesControllerRemove operation in DefaultApi.
 * @export
 * @interface DefaultApiZonesControllerRemoveRequest
 */
export interface DefaultApiZonesControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiZonesControllerRemove
     */
    readonly id: string
}

/**
 * Request parameters for zonesControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiZonesControllerUpdateRequest
 */
export interface DefaultApiZonesControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiZonesControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateZoneDto}
     * @memberof DefaultApiZonesControllerUpdate
     */
    readonly updateZoneDto: UpdateZoneDto
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {DefaultApiAdminUsersControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUsersControllerCreate(requestParameters: DefaultApiAdminUsersControllerCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUsersControllerCreate(requestParameters.createAdminUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAdminUsersControllerCreateWardrobeAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUsersControllerCreateWardrobeAdmin(requestParameters: DefaultApiAdminUsersControllerCreateWardrobeAdminRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUsersControllerCreateWardrobeAdmin(requestParameters.createWardrobeAdminDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAdminUsersControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUsersControllerFindAll(requestParameters: DefaultApiAdminUsersControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUsersControllerFindAll(requestParameters.facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUsersControllerFindMe(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUsersControllerFindMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAdminUsersControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUsersControllerFindOne(requestParameters: DefaultApiAdminUsersControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUsersControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAdminUsersControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUsersControllerRemove(requestParameters: DefaultApiAdminUsersControllerRemoveRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUsersControllerRemove(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAdminUsersControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUsersControllerUpdate(requestParameters: DefaultApiAdminUsersControllerUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUsersControllerUpdate(requestParameters.id, requestParameters.updateAdminUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAdminUsersControllerUpdateFacilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminUsersControllerUpdateFacility(requestParameters: DefaultApiAdminUsersControllerUpdateFacilityRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminUsersControllerUpdateFacility(requestParameters.updateAdminUserFacilityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerTestError(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerTestError(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAuthControllerGetSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerGetSession(requestParameters: DefaultApiAuthControllerGetSessionRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerGetSession(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAuthControllerInitAdminPhoneSignInRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerInitAdminPhoneSignIn(requestParameters: DefaultApiAuthControllerInitAdminPhoneSignInRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerInitAdminPhoneSignIn(requestParameters.initAdminPhoneDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAuthControllerInitUserPhoneSignInRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerInitUserPhoneSignIn(requestParameters: DefaultApiAuthControllerInitUserPhoneSignInRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerInitUserPhoneSignIn(requestParameters.initUserPhoneSignInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAuthControllerInitUserPhoneSignInByAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerInitUserPhoneSignInByAdmin(requestParameters: DefaultApiAuthControllerInitUserPhoneSignInByAdminRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerInitUserPhoneSignInByAdmin(requestParameters.initUserPhoneSignInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerInitUserSignIn(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerInitUserSignIn(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerSignInAdmin(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerSignInAdmin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiAuthControllerSignInAdminByPhoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerSignInAdminByPhone(requestParameters: DefaultApiAuthControllerSignInAdminByPhoneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerSignInAdminByPhone(requestParameters.signInAdminPhoneDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerSignInUser(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerSignInUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerTestRateLimit(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerTestRateLimit(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCompaniesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerCreate(requestParameters: DefaultApiCompaniesControllerCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerCreate(requestParameters.createCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCompaniesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerFindOne(requestParameters: DefaultApiCompaniesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCompaniesControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerRemove(requestParameters: DefaultApiCompaniesControllerRemoveRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerRemove(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCompaniesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public companiesControllerUpdate(requestParameters: DefaultApiCompaniesControllerUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).companiesControllerUpdate(requestParameters.id, requestParameters.updateCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerCreateTransactionsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerCreateTransactionsReport(requestParameters: DefaultApiDashboardControllerCreateTransactionsReportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerCreateTransactionsReport(requestParameters.createReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerCreateZReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerCreateZReport(requestParameters: DefaultApiDashboardControllerCreateZReportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerCreateZReport(requestParameters.createReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerExportUsersForFacilityToCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerExportUsersForFacilityToCsv(requestParameters: DefaultApiDashboardControllerExportUsersForFacilityToCsvRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerExportUsersForFacilityToCsv(requestParameters.facilityId, requestParameters.startDate, requestParameters.endDate, requestParameters.gender, requestParameters.age, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerGetFacilityEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerGetFacilityEvents(requestParameters: DefaultApiDashboardControllerGetFacilityEventsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerGetFacilityEvents(requestParameters.facilityId, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerGetOverviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerGetOverview(requestParameters: DefaultApiDashboardControllerGetOverviewRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerGetOverview(requestParameters.endDate, requestParameters.startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerGetReportDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerGetReportData(requestParameters: DefaultApiDashboardControllerGetReportDataRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerGetReportData(requestParameters.createReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerGetUsersForFacilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerGetUsersForFacility(requestParameters: DefaultApiDashboardControllerGetUsersForFacilityRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerGetUsersForFacility(requestParameters.facilityId, requestParameters.startDate, requestParameters.endDate, requestParameters.gender, requestParameters.age, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerRemoveAdminUserFromFacilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerRemoveAdminUserFromFacility(requestParameters: DefaultApiDashboardControllerRemoveAdminUserFromFacilityRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerRemoveAdminUserFromFacility(requestParameters.facilityId, requestParameters.adminUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerStatistics(requestParameters: DefaultApiDashboardControllerStatisticsRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerStatistics(requestParameters.superFacilityId, requestParameters.endDate, requestParameters.startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerTest(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerTestSocketUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerTestSocketUpdate(requestParameters: DefaultApiDashboardControllerTestSocketUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerTestSocketUpdate(requestParameters.facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardControllerTriggerSlackReportForDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardControllerTriggerSlackReportForDate(requestParameters: DefaultApiDashboardControllerTriggerSlackReportForDateRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardControllerTriggerSlackReportForDate(requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardSmsControllerCallbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardSmsControllerCallback(requestParameters: DefaultApiDashboardSmsControllerCallbackRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardSmsControllerCallback(requestParameters.gatewayAPICallbackBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardSmsControllerGetBulkSmsForFacilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardSmsControllerGetBulkSmsForFacility(requestParameters: DefaultApiDashboardSmsControllerGetBulkSmsForFacilityRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardSmsControllerGetBulkSmsForFacility(requestParameters.facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardSmsControllerGetSmsForUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardSmsControllerGetSmsForUser(requestParameters: DefaultApiDashboardSmsControllerGetSmsForUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardSmsControllerGetSmsForUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardSmsControllerSendBulkSMSRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardSmsControllerSendBulkSMS(requestParameters: DefaultApiDashboardSmsControllerSendBulkSMSRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardSmsControllerSendBulkSMS(requestParameters.sendSmsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiDashboardSmsControllerSendSMSToUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardSmsControllerSendSMSToUser(requestParameters: DefaultApiDashboardSmsControllerSendSMSToUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardSmsControllerSendSMSToUser(requestParameters.sendSmsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEventsControllerGetEventsByAdminUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public eventsControllerGetEventsByAdminUser(requestParameters: DefaultApiEventsControllerGetEventsByAdminUserRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).eventsControllerGetEventsByAdminUser(requestParameters.scope, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEventsControllerGetEventsBySlotIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public eventsControllerGetEventsBySlotId(requestParameters: DefaultApiEventsControllerGetEventsBySlotIdRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).eventsControllerGetEventsBySlotId(requestParameters.slotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEventsControllerGetEventsByUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public eventsControllerGetEventsByUserId(requestParameters: DefaultApiEventsControllerGetEventsByUserIdRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).eventsControllerGetEventsByUserId(requestParameters.userId, requestParameters.scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFacilitiesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilitiesControllerCreate(requestParameters: DefaultApiFacilitiesControllerCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilitiesControllerCreate(requestParameters.createFacilityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilitiesControllerGetAllFacilities(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilitiesControllerGetAllFacilities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFacilitiesControllerGetEntranceSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilitiesControllerGetEntranceSummary(requestParameters: DefaultApiFacilitiesControllerGetEntranceSummaryRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilitiesControllerGetEntranceSummary(requestParameters.id, requestParameters.since, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilitiesControllerGetFacilitiesByUser(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilitiesControllerGetFacilitiesByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilitiesControllerGetFacilitiesForPayments(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilitiesControllerGetFacilitiesForPayments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFacilitiesControllerGetFacilityByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilitiesControllerGetFacilityByName(requestParameters: DefaultApiFacilitiesControllerGetFacilityByNameRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilitiesControllerGetFacilityByName(requestParameters.facilityName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFacilitiesControllerGetFacilityEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilitiesControllerGetFacilityEvents(requestParameters: DefaultApiFacilitiesControllerGetFacilityEventsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilitiesControllerGetFacilityEvents(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFacilitiesControllerGetFacilityHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilitiesControllerGetFacilityHistory(requestParameters: DefaultApiFacilitiesControllerGetFacilityHistoryRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilitiesControllerGetFacilityHistory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilitiesControllerTestUpdate(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilitiesControllerTestUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFacilitiesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public facilitiesControllerUpdate(requestParameters: DefaultApiFacilitiesControllerUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).facilitiesControllerUpdate(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFeatureFlagControllerIsEnabledRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public featureFlagControllerIsEnabled(requestParameters: DefaultApiFeatureFlagControllerIsEnabledRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).featureFlagControllerIsEnabled(requestParameters.feature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFeatureFlagControllerIsEnabledForFacilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public featureFlagControllerIsEnabledForFacility(requestParameters: DefaultApiFeatureFlagControllerIsEnabledForFacilityRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).featureFlagControllerIsEnabledForFacility(requestParameters.feature, requestParameters.facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFeatureFlagControllerIsEnabledForPaymentCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public featureFlagControllerIsEnabledForPaymentCode(requestParameters: DefaultApiFeatureFlagControllerIsEnabledForPaymentCodeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).featureFlagControllerIsEnabledForPaymentCode(requestParameters.feature, requestParameters.paymentCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerActivePaymentsForUserByAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerActivePaymentsForUserByAdmin(requestParameters: DefaultApiPaymentsControllerActivePaymentsForUserByAdminRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerActivePaymentsForUserByAdmin(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerConfirmPaymentIntentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerConfirmPaymentIntent(requestParameters: DefaultApiPaymentsControllerConfirmPaymentIntentRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerConfirmPaymentIntent(requestParameters.paymentIntentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerConfirmSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerConfirmSession(requestParameters: DefaultApiPaymentsControllerConfirmSessionRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerConfirmSession(requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerFindAllByUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerFindAllByUser(requestParameters: DefaultApiPaymentsControllerFindAllByUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerFindAllByUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerFindOne(requestParameters: DefaultApiPaymentsControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerFindOneForUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerFindOneForUser(requestParameters: DefaultApiPaymentsControllerFindOneForUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerFindOneForUser(requestParameters.paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerFindPaymentsForUser(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerFindPaymentsForUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerInitPaymentForFacilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerInitPaymentForFacility(requestParameters: DefaultApiPaymentsControllerInitPaymentForFacilityRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerInitPaymentForFacility(requestParameters.facilityId, requestParameters.initPaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerInitPaymentIntentForFacilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerInitPaymentIntentForFacility(requestParameters: DefaultApiPaymentsControllerInitPaymentIntentForFacilityRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerInitPaymentIntentForFacility(requestParameters.facilityId, requestParameters.initPaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerInitSwishByAdmin(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerInitSwishByAdmin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerInitSwishForFacilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerInitSwishForFacility(requestParameters: DefaultApiPaymentsControllerInitSwishForFacilityRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerInitSwishForFacility(requestParameters.facilityId, requestParameters.initPaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerIntentStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerIntentStatus(requestParameters: DefaultApiPaymentsControllerIntentStatusRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerIntentStatus(requestParameters.paymentIntentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerSessionStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerSessionStatus(requestParameters: DefaultApiPaymentsControllerSessionStatusRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerSessionStatus(requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerSwishCallbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerSwishCallback(requestParameters: DefaultApiPaymentsControllerSwishCallbackRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerSwishCallback(requestParameters.swishCallbackBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerSwishCallbackQrCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerSwishCallbackQrCode(requestParameters: DefaultApiPaymentsControllerSwishCallbackQrCodeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerSwishCallbackQrCode(requestParameters.swishCallbackBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerSwishStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerSwishStatus(requestParameters: DefaultApiPaymentsControllerSwishStatusRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerSwishStatus(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerSwishStatusByAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerSwishStatusByAdmin(requestParameters: DefaultApiPaymentsControllerSwishStatusByAdminRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerSwishStatusByAdmin(requestParameters.swishPaymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPaymentsControllerTriggerPaymentForUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerTriggerPaymentForUser(requestParameters: DefaultApiPaymentsControllerTriggerPaymentForUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerTriggerPaymentForUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRedirectsControllerRedirectToOriginalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public redirectsControllerRedirectToOriginal(requestParameters: DefaultApiRedirectsControllerRedirectToOriginalRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).redirectsControllerRedirectToOriginal(requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRefundRequestsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refundRequestsControllerCreate(requestParameters: DefaultApiRefundRequestsControllerCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).refundRequestsControllerCreate(requestParameters.createRefundRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refundRequestsControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).refundRequestsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRefundRequestsControllerFindAllByFacilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refundRequestsControllerFindAllByFacility(requestParameters: DefaultApiRefundRequestsControllerFindAllByFacilityRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).refundRequestsControllerFindAllByFacility(requestParameters.facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRefundRequestsControllerFindAllByUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refundRequestsControllerFindAllByUser(requestParameters: DefaultApiRefundRequestsControllerFindAllByUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).refundRequestsControllerFindAllByUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRefundRequestsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refundRequestsControllerFindOne(requestParameters: DefaultApiRefundRequestsControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).refundRequestsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRefundRequestsControllerRefundByAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refundRequestsControllerRefundByAdmin(requestParameters: DefaultApiRefundRequestsControllerRefundByAdminRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).refundRequestsControllerRefundByAdmin(requestParameters.createRefundRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRefundRequestsControllerSwishCallbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refundRequestsControllerSwishCallback(requestParameters: DefaultApiRefundRequestsControllerSwishCallbackRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).refundRequestsControllerSwishCallback(requestParameters.swishRefund, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRefundRequestsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refundRequestsControllerUpdate(requestParameters: DefaultApiRefundRequestsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).refundRequestsControllerUpdate(requestParameters.id, requestParameters.updateRefundRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerAddAdditionalUserToSlotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerAddAdditionalUserToSlot(requestParameters: DefaultApiSlotsControllerAddAdditionalUserToSlotRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerAddAdditionalUserToSlot(requestParameters.slotId, requestParameters.addAdditionalUserToSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerArchiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerArchive(requestParameters: DefaultApiSlotsControllerArchiveRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerArchive(requestParameters.archiveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerArchivedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerArchived(requestParameters: DefaultApiSlotsControllerArchivedRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerArchived(requestParameters.facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerCheckInRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerCheckIn(requestParameters: DefaultApiSlotsControllerCheckInRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerCheckIn(requestParameters.checkInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerCheckOutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerCheckOut(requestParameters: DefaultApiSlotsControllerCheckOutRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerCheckOut(requestParameters.checkOutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerCheckOutByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerCheckOutById(requestParameters: DefaultApiSlotsControllerCheckOutByIdRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerCheckOutById(requestParameters.slotId, requestParameters.checkOutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerGetBySlotIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerGetBySlotId(requestParameters: DefaultApiSlotsControllerGetBySlotIdRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerGetBySlotId(requestParameters.slotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerGetByUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerGetByUserId(requestParameters: DefaultApiSlotsControllerGetByUserIdRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerGetByUserId(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerGetByZoneIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerGetByZoneId(requestParameters: DefaultApiSlotsControllerGetByZoneIdRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerGetByZoneId(requestParameters.zoneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerMoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerMove(requestParameters: DefaultApiSlotsControllerMoveRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerMove(requestParameters.moveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerReserveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerReserve(requestParameters: DefaultApiSlotsControllerReserveRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerReserve(requestParameters.reserveSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerReserveSpecificSlotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerReserveSpecificSlot(requestParameters: DefaultApiSlotsControllerReserveSpecificSlotRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerReserveSpecificSlot(requestParameters.slotId, requestParameters.reserveSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerRevertCheckoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerRevertCheckout(requestParameters: DefaultApiSlotsControllerRevertCheckoutRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerRevertCheckout(requestParameters.eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerSearch(requestParameters: DefaultApiSlotsControllerSearchRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerSearch(requestParameters.facilityId, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerUnreserveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerUnreserve(requestParameters: DefaultApiSlotsControllerUnreserveRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerUnreserve(requestParameters.unreserveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerUnreserveSpecificSlotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerUnreserveSpecificSlot(requestParameters: DefaultApiSlotsControllerUnreserveSpecificSlotRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerUnreserveSpecificSlot(requestParameters.slotId, requestParameters.unreserveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerUpdate(requestParameters: DefaultApiSlotsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerUpdate(requestParameters.id, requestParameters.updateSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSlotsControllerValidateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slotsControllerValidate(requestParameters: DefaultApiSlotsControllerValidateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).slotsControllerValidate(requestParameters.id, requestParameters.validateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiTicketsControllerConsumeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ticketsControllerConsume(requestParameters: DefaultApiTicketsControllerConsumeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ticketsControllerConsume(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiTicketsControllerResetConsumedAtRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ticketsControllerResetConsumedAt(requestParameters: DefaultApiTicketsControllerResetConsumedAtRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ticketsControllerResetConsumedAt(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiTicketsControllerValidateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ticketsControllerValidate(requestParameters: DefaultApiTicketsControllerValidateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ticketsControllerValidate(requestParameters.id, requestParameters.validateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerConfirmAddPhoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerConfirmAddPhone(requestParameters: DefaultApiUsersControllerConfirmAddPhoneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerConfirmAddPhone(requestParameters.id, requestParameters.phone, requestParameters.otp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerCreate(requestParameters: DefaultApiUsersControllerCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerCreate(requestParameters.createUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerFindMe(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerFindMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerGetBySSNRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerGetBySSN(requestParameters: DefaultApiUsersControllerGetBySSNRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerGetBySSN(requestParameters.ssn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerGetQrCodes(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerGetQrCodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerGetUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerGetUserById(requestParameters: DefaultApiUsersControllerGetUserByIdRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerGetUserById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerInitAddPhoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerInitAddPhone(requestParameters: DefaultApiUsersControllerInitAddPhoneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerInitAddPhone(requestParameters.id, requestParameters.phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerRemove(requestParameters: DefaultApiUsersControllerRemoveRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerRemove(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerSearch(requestParameters: DefaultApiUsersControllerSearchRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerSearch(requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerSearchByBirthdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerSearchByBirthdate(requestParameters: DefaultApiUsersControllerSearchByBirthdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerSearchByBirthdate(requestParameters.searchByBirthdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerUpdate(requestParameters: DefaultApiUsersControllerUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerUpdate(requestParameters.id, requestParameters.updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiZonesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zonesControllerCreate(requestParameters: DefaultApiZonesControllerCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zonesControllerCreate(requestParameters.createZoneDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiZonesControllerFindAllByFacilityIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zonesControllerFindAllByFacilityId(requestParameters: DefaultApiZonesControllerFindAllByFacilityIdRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zonesControllerFindAllByFacilityId(requestParameters.facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiZonesControllerFindBySlotIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zonesControllerFindBySlotId(requestParameters: DefaultApiZonesControllerFindBySlotIdRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zonesControllerFindBySlotId(requestParameters.slotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiZonesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zonesControllerFindOne(requestParameters: DefaultApiZonesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zonesControllerFindOne(requestParameters.zoneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiZonesControllerFindOneWithSlotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zonesControllerFindOneWithSlots(requestParameters: DefaultApiZonesControllerFindOneWithSlotsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zonesControllerFindOneWithSlots(requestParameters.zoneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiZonesControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zonesControllerRemove(requestParameters: DefaultApiZonesControllerRemoveRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zonesControllerRemove(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiZonesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zonesControllerUpdate(requestParameters: DefaultApiZonesControllerUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zonesControllerUpdate(requestParameters.id, requestParameters.updateZoneDto, options).then((request) => request(this.axios, this.basePath));
    }
}


