import { Dialog, DialogBackdrop } from '@headlessui/react';
import {
  Bars3Icon,
  QrCodeIcon,
  XMarkIcon,
  // TicketIcon
} from '@heroicons/react/24/outline';
import QRCode from 'qrcode.react';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTickets } from '../api/queries';
import paidTicketsBG from '../assets/paid-tickets-bg.png';
import { classNames, handleinteractions } from '../helpers';
import { useProtectedContext } from './protected-route';
import { useTranslation } from 'react-i18next';
// import { useDashboardContext } from '../context/dashboard';
import { useAuth } from '../hooks/use-auth';

export const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { user } = useAuth();

  const isPaidView = pathname.includes('paid');
  const isCheckedInView = pathname.includes('checked-in');

  const { toggleDrawer, personalTicketOpen, closePersonalTicket, openPersonalTicket } =
    useProtectedContext();
  // const { setPaymentModalOpen } = useDashboardContext();
  const { personal, checkedIn, paid } = useTickets();

  const navigate = useNavigate();
  if (isCheckedInView && checkedIn?.length === 0) {
    navigate('/dashboard/paid');
  }

  if (isPaidView && paid?.length === 0) {
    navigate('/dashboard');
  }

  if (!isPaidView && !isCheckedInView) {
    if (checkedIn && checkedIn.length > 0) {
      navigate('/dashboard/checked-in');
    } else if (paid && paid.length > 0) {
      navigate('/dashboard/paid');
    }
  }
  return (
    <>
      <div
        className="h-full w-full flex flex-col text-white bg-cover bg-center bg-no-repeat"
        style={isPaidView ? { backgroundImage: `url(${paidTicketsBG})` } : {}}
      >
        <div className="flex justify-evenly p-4 gap-4">
          <div className="flex-1">
            <Link to="/dashboard/checked-in">
              <button
                disabled={checkedIn?.length === 0}
                type="button"
                className={classNames(
                  'relative rounded-full w-full  px-3 py-1.5 text-sm font-medium text-black shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white',
                  isCheckedInView ? 'bg-white text-black' : 'bg-transparent text-white',
                  'disabled:opacity-50',
                )}
              >
                <>
                  {t('general.checked-in')}
                  <div className="absolute top-1 left-2 bottom-1 flex items-center justify-center">
                    <span
                      className={classNames(
                        'rounded-full h-5 w-5',
                        isCheckedInView ? 'bg-black text-white' : 'bg-white text-black',
                      )}
                    >
                      {checkedIn?.length}
                    </span>
                  </div>
                </>
              </button>
            </Link>
          </div>
          <div className="flex-1">
            <Link to="/dashboard/paid">
              <button
                disabled={paid?.length === 0}
                type="button"
                className={classNames(
                  'relative rounded-full w-full  px-3 py-1.5 text-sm font-medium text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white',
                  isPaidView ? 'bg-white text-black' : 'bg-transparent text-white',
                  'disabled:opacity-50',
                )}
              >
                <>
                  {t('general.paid')}
                  <div className="absolute top-1 right-2 bottom-1 flex items-center justify-center">
                    <span
                      className={classNames(
                        'rounded-full h-5 w-5',
                        isPaidView ? 'bg-black text-white' : 'bg-white text-black',
                      )}
                    >
                      {paid?.length}
                    </span>
                  </div>
                </>
              </button>
            </Link>
          </div>
        </div>
        <div className="flex flex-1 w-full overflow-hidden">{children}</div>
        <div className="flex justify-between p-4 gap-4 items-center">
          <div>
            {' '}
            <button
              {...handleinteractions(openPersonalTicket)}
              type="button"
              className="rounded-full bg-white p-2 text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              <QrCodeIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="flex flex-1">
            {/* <button
              {...handleinteractions(() => setPaymentModalOpen && setPaymentModalOpen(true))}
              type="button"
              className="border-blue-400 border-2 rounded-full w-full bg-white p-2 text-lg font-medium text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white flex gap-4 justify-center items-center"
            >
              <>
                {t('tickets.buy-ticket')}
                <TicketIcon className="h-6 w-6" />
              </>
            </button> */}
          </div>
          <div>
            {' '}
            <button
              {...handleinteractions(toggleDrawer)}
              type="button"
              className={classNames(
                !user?.phone && window.location.pathname !== '/dashboard'
                  ? 'animate-bounce border-blue-400 border-4'
                  : '',
                'rounded-full bg-white p-2 text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white',
              )}
            >
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      <Dialog
        open={personalTicketOpen}
        onClose={closePersonalTicket}
        className="relative z-10"
        {...handleinteractions(closePersonalTicket)}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-bgPrimary 
           transition-opacity data-[closed]:opacity-0 data-[enter]:duration-70 data-[leave]:duration-50 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0 flex-col gap-8">
            <span className="text-4xl text-white">{t('tickets.show-qr')}</span>
            <div className="p-6 bg-white rounded-3xl">
              {personal && <QRCode value={JSON.stringify(personal)} size={255} />}
            </div>
            <div className="bg-textPrimary rounded-full p-2 mt-8">
              <XMarkIcon className="size-12 text-bgPrimary" />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
