import { motion } from 'framer-motion';
import QRCode from 'qrcode.react';
import { useProtectedContext } from '../components';
import { AddPhoneButton } from '../components/add-phone-button';
import { QRCode as CheckedOutQRCode } from '../generated';
import { useAuth } from '../hooks/use-auth';

interface Props {
  qrCode: CheckedOutQRCode;
}

const animation = {
  initial: { y: 7, opacity: 0 },
  animate: { y: 0, opacity: 1 },
};

export const CheckedOutTicket = ({ qrCode }: Props) => {
  const { openAddPhone } = useProtectedContext();
  const { user } = useAuth();

  return (
    <>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          height: '100%',
          width: '100%',
          gap: 20,
        }}
      >
        <span className="text-4xl text-white text-center">Visa upp för garderoben</span>
        <motion.div
          style={{
            padding: 8,
          }}
          className="flex flex-col items-center justify-center gap-4"
          initial="initial"
          animate="animate"
          variants={animation}
        >
          <div className="p-6 bg-white rounded-3xl">
            <QRCode value={JSON.stringify(qrCode)} size={255} />
          </div>
        </motion.div>
        {!user?.phone && <AddPhoneButton onClick={openAddPhone} />}
      </section>
    </>
  );
};
